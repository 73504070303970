@media (max-width: 518.4px) {
  .login-logo-bg img {
    display: none;
  }

  .login-area {
    position: absolute;
    height: 99.9vh;
    width: 100% !important;
    display: none;
    vertical-align: top !important;
  }

  .login-card {
    margin: 0 auto;
    padding: 0px 3.6px;
    gap: 7.2px;
    isolation: isolate;
    max-width: 337.5px;
    box-shadow: none;
    border: none !important;
  }

  .login-logo img {
    width: 156.987px;
    left: 38.7px;
    position: relative;
  }
  .login-logo {
    margin: 0 auto;
    padding: 0px 3.6px;
    gap: 7.2px;
    justify-content: left;
    width: 337.5;
    max-width: 337.5px;
    margin-bottom: 21.6px;
  }
}

@media (min-width: 518.4px) and (max-width: 747px) {
  .login-logo img {
    width: 207px !important;
    padding-top: 72px;
  }
}
