@import "/src/assets/styles/functions";
@import "/src/assets/styles/variables";
.k-grid {
  border-radius: $px-10 $px-10 $px-10 $px-10;
}

/******** toolbar style *********/

.k-grid-toolbar {
  background-color: #f1f5f9;
  border-radius: $px-10 $px-10 0px 0px;
  padding: $px-10 $px-16;
  border: 0px;

  .datepicker-custom-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: $px-32;
    width: 100%;
    border: $px-1 solid #c9cdd3 !important;
    border-radius: $px-6;
    padding: $px-6 $px-14;
    background-image: unset;
    color: #b3b8c2;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: $px-14;
    line-height: $px-24;
    letter-spacing: -$px-0-2;

    .k-button-icon {
      color: #9da4b0;
    }

    .k-input-inner {
      padding: 0px;
      z-index: 0;

      &::placeholder {
        color: #b3b8c2 !important;
      }
    }

    &:hover {
      background-color: #fff;
    }

    &:focus-within {
      border-color: #154876 !important;
      box-shadow:
        0px 0px 0px $px-1 #ffffff,
        0px 0px 0px $px-4 #b1dcff;
    }

    &[aria-expanded="true"] {
      border-color: #154876 !important;
      box-shadow:
        0px 0px 0px $px-1 #ffffff,
        0px 0px 0px $px-4 #b1dcff;
      color: #154876;
    }

    &.ng-dirty {
      color: #154876 !important;

      .k-i-caret-alt-down::before {
        color: #154876;
      }
    }

    &.is-invalid {
      border: $px-1 solid #dc362e !important;
      color: #dc362e !important;
      background-color: #fceeee;

      &[aria-expanded="true"] {
        border-color: #dc362e !important;
        box-shadow: unset;
      }

      .k-i-caret-alt-down::before {
        color: #dc362e;
      }

      .k-input-inner {
        &::placeholder {
          color: #dc362e !important;
        }
      }

      .k-button-icon {
        color: #dc362e !important;
      }
    }

    .k-i-caret-alt-down::before {
      color: #c9cdd3;
    }

    kendo-actionsheet {
      display: none;
    }
  }
}

/************* search input style *******************/

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: $px-32;

  .input-line {
    position: absolute;
    top: $px-8;
    left: $px-44;
    width: $px-1;
    height: $px-16;
    background-color: #dee1e5;
    z-index: 100;
  }

  .search-icon {
    position: absolute;
    top: $px-8;
    left: $px-18;
  }
}

.search-input {
  width: 100%;
  padding-left: $px-52;
  height: 100%;
  font-size: $px-14;
  border: $px-1 solid #c9cdd3;
  border-radius: $px-6;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  min-height: $px-32;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  outline: 0px;
}

.search-input::placeholder {
  color: #b3b8c2;
}

.search-input:focus {
  border-color: #154876;
  box-shadow:
    0px 0px 0px $px-1 #ffffff,
    0px 0px 0px $px-4 #b1dcff;
  color: #154876;
}

.has-text {
  border-color: #154876;
  color: #154876;
}

.icon-wrapper {
  display: flex;
}

.input-label {
  position: absolute;
  top: $px-8;
  left: $px-44;
  font-size: $px-12;
  color: #777;
  transition: opacity 0.2s ease-in-out;
}

/*******************  Export Buttons ****************/

.buttonsContainer {
  display: flex;

  gap: $px-8;
  width: 100%;
  justify-content: flex-end;
}

.exportButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exportButton .k-button-text {
  display: flex;
  justify-content: space-between;
  padding: $px-6 $px-16 $px-6 $px-12;
  gap: $px-8;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $px-12;
  line-height: $px-20;
  letter-spacing: -$px-0-2;
  color: #64748b;
  width: $px-136;
  height: $px-32;
  background-color: #ffffff;
  border: $px-1 solid #e2e8f0;
  border-radius: $px-6;
}

.exportButton .k-button-text:hover {
  background-color: #f8fafc;
  color: #475569;
}

/********** filters style ***********/

.k-actions {
  display: none;
}

.k-filtercell-operator {
  min-width: fit-content;
}

.k-grid-header .k-sort-icon,
.k-grid-header .k-sort-order {
  color: #417eb8;
}

.k-filtercell .k-color-picker,
.k-filtercell .k-colorpicker,
.k-filtercell .k-dropdown-operator {
  height: $px-30 !important;
}

.k-columnmenu-item.k-selected {
  background-color: rgba(217, 242, 255, 0.5);
  color: #334155;
}

.k-columnmenu-item:focus,
.k-columnmenu-item.k-focus {
  box-shadow: unset;
}

.k-column-list-item:hover,
.k-columnmenu-item:hover {
  background-color: #f8fafc;
}

.k-grid .k-grid-header .k-table-th col.k-sorted,
.k-table-th.k-sorted {
  background-color: unset;
}

.k-columnmenu-item {
  display: flex;
  align-items: center;
  height: $px-36;
  gap: $px-12;
  padding: $px-6 $px-12;
}

.k-filter-menu-container {
  gap: $px-8;
}

.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  width: 50%;
}

.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active,
.k-grid-header .k-hierarchy-cell .k-svg-icon.k-active {
  color: white;
  background-color: #417eb8;
}

.k-columnmenu-item .k-icon {
  color: #94a3b8;
}

.k-grid-header .k-grid-filter:focus,
.k-grid-header .k-grid-filter.k-focus,
.k-grid-header .k-header-column-menu:focus,
.k-grid-header .k-header-column-menu.k-focus,
.k-grid-header .k-grid-header-menu:focus,
.k-grid-header .k-grid-header-menu.k-focus,
.k-grid-header .k-hierarchy-cell .k-icon:focus,
.k-grid-header .k-hierarchy-cell .k-icon.k-focus,
.k-grid-header .k-hierarchy-cell .k-svg-icon:focus,
.k-grid-header .k-hierarchy-cell .k-svg-icon.k-focus {
  box-shadow: unset;
}

.k-grid-header .k-grid-filter:hover,
.k-grid-header .k-header-column-menu:hover,
.k-grid-header .k-grid-header-menu:hover,
.k-grid-header .k-hierarchy-cell .k-icon:hover,
.k-grid-header .k-hierarchy-cell .k-svg-icon:hover {
  background-color: #f8fafc;
}

/********************  table styles  ****************/

.k-grid-content {
  &::-webkit-scrollbar {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e8f0;
    border-radius: $px-6;
    height: $px-100 !important;
  }
}

.k-grid td.k-selected,
.k-grid .k-table-row.k-selected > td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected > .k-table-td {
  background-color: rgba(217, 242, 255, 0.5);
}

.k-grid-md .k-table-th {
  padding-block: $px-10;
  padding-inline: $px-16;
  line-height: $px-23;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: $px-14;
  text-align: left;
  letter-spacing: -$px-0-2;
  color: #334155;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.k-button-solid-base {
  border: unset;
  background-color: unset;
  background-image: unset;
  padding: 0;
}

.k-button-solid-base:hover {
  background-color: unset;
}

.edit-buttons {
  display: flex;
  justify-content: flex-start;
  gap: $px-18;
}

.k-grid-aria-root {
  border-top: $px-1 solid #cbd5e1;
}

.k-grid .k-table-row.k-table-alt-row {
  background-color: #fff;
}

.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
  background-color: #f8fafc;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #2e6fa6;
  background-color: #2e6fa6;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: unset;
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  color: #2e6fa6;
  background-image: unset;
}

.k-checkbox:focus,
.k-checkbox.k-focus {
  box-shadow: unset;
}

.k-checkbox {
  margin-top: $px-2;
}

td.k-table-td.k-touch-action-auto {
  padding: 7.9px $px-15;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $px-13;
  text-align: left;
  letter-spacing: -$px-0-2;
  color: #334155;
  border-bottom: $px-1 solid #e2e8f0;
  line-height: $px-19;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.msg-dropdowm-action {
  .delete-button {
    color: #dc362e;

    &:hover {
      color: #dc362e;
    }
  }

  .disabled {
    background-color: #f8fafc;
    color: #c9cdd3;
  }
}

/*********** Badges Style ***********/

.Badges {
  display: flex;
  justify-content: center;
}

/************ pager style **************/

.k-grid-pager {
  height: $px-40 !important;
  padding: $px-10 $px-16;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f5f9;
  font-size: 12.6px;
}

.k-grid .k-grid-pager:focus {
  box-shadow: unset;
}

.k-button-flat {
  background-color: white !important;
  color: #417eb8;
  width: $px-36;
  height: $px-36;
}

.k-button.k-selected::before {
  background-color: white !important;
  color: #417eb8;
}

.k-button-text {
  color: #417eb8;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $px-14;
  line-height: $px-17;
}

.k-pager-numbers .k-selected {
  background-color: #2e6fa6 !important;
  z-index: 0;
}

.k-pager-numbers:hover {
  cursor: pointer;
}

.k-pager-numbers .k-selected .k-button-text {
  color: #fff;
}

.k-picker-solid:focus,
.k-picker-solid.k-focus {
  box-shadow: unset;
}

.k-button-icon {
  color: #417eb8;
}

.k-pager-md {
  gap: 0px;
}

.k-pager-md .k-pager-sizes .k-dropdown-list,
.k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist {
  background-color: #fff;
  margin-left: $px-20;
  width: $px-84 !important;
  height: $px-30 !important;
  font-size: 12.6px;
}

.k-icon {
  font-size: $px-16;
}

.girdhover {
  padding: $px-10;
  color: #787878;
  background-color: #fcf7f8;
  border: $px-1 solid rgba(0, 0, 0, 0.05);
}

/*************** start of SCSS ***************/
/************************ Students form grid customize ************************/
.modal-custom_forms_Student {
  .k-grid {
    height: $px-170;

    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    td.k-table-td {
      border: 0px;
    }

    .k-grid-aria-root {
      border-top: unset;
    }
  }
}

/*************  grid style districts page **********************/

.tab-content {
  padding: $px-16;
  background-color: #fff;
  box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  border-radius: $px-13;

  .grid-container {
    .k-grid {
      height: $px-271;

      .k-grid-header {
        thead.k-table-thead {
          background-color: #f1f5f9;

          .k-table-th {
            border: 0px;
          }
        }
      }

      td.k-table-td {
        border: 0px;
      }

      .k-grid-aria-root {
        border-top: unset !important;
      }
    }
  }
}

kendo-label > .k-label {
  margin: 0;
  opacity: 0.8;
}

.k-pager-info {
  opacity: 0.8;
}

/************************ Guardian form grid customize ************************/
.modal-custom_forms_Guardian {
  .k-grid {
    height: $px-170;

    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    td.k-table-td {
      border: 0px;
    }

    .k-grid-aria-root {
      border-top: unset;
      border-radius: inherit;
    }
  }
}

/************************ Students form grid customize ************************/
.modal-custom_forms_studentsAdd {
  .k-grid {
    height: $px-170;

    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    td.k-table-td {
      border: 0px;
    }

    .k-grid-aria-root {
      border-top: unset;
      border-radius: inherit;
    }
  }
}

/************************ Students form grid guardian ************************/
.modal-custom_forms_Guardians {
  .k-grid {
    height: $px-170;

    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    td.k-table-td {
      border: 0px;
    }

    .k-grid-aria-root {
      border-top: unset;
      border-radius: inherit;
    }
  }
}

/************************ regions And Locations form grid customize ************************/
.modal-custom_forms_RegionsLocations {
  .k-grid {
    height: $px-170;

    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    td.k-table-td {
      border: 0px;
    }

    .k-grid-aria-root {
      border-top: unset;
    }
    .text-link {
      color: #417eb8;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

/************************ tools form grid customize ************************/
.modal-custom_forms_tools {
  .k-grid {
    height: $px-170;

    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    td.k-table-td {
      border: 0px;
    }

    .k-grid-aria-root {
      border-top: unset;
    }
  }
}

.tooltipGrid {
  display: flex;
  justify-content: center;
}

.tooltipGridLeft {
  display: flex;
  justify-content: left;
}

/************************ tools form grid customize ************************/

.modal-custom_forms {
  .k-grid {
    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    kendo-grid-list {
      .k-grid-content {
        overflow-y: auto;
        td.k-table-td {
          border: 0px;
        }

        .k-grid-aria-root {
          border-top: unset;
        }
        .text-link {
          color: #417eb8;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

/************************ school for districts grid customize ************************/
.modal-custom_forms_addNewSchool_districts {
  .k-grid {
    height: $px-170;

    .k-grid-header {
      padding: 0px !important;

      thead.k-table-thead {
        background-color: #f1f5f9;

        .k-table-th {
          border: 0px;
        }
      }
    }

    td.k-table-td {
      border: 0px;
    }

    .k-grid-aria-root {
      border-top: unset;
      border-radius: inherit;
    }
  }
}
.k-pager-prev .k-icon, .k-pager-prev span {
  font-size: 12.6px; /* Change this value as needed */
}