$px-0: 0px;
$px-0-2: decrease-by-10-percent(0.2px);
$px-0-3: decrease-by-10-percent(0.3px);
$px-0-5: decrease-by-10-percent(0.5px);
$px-1: decrease-by-10-percent(1px);
$px-2: decrease-by-10-percent(2px);
$px-3: decrease-by-10-percent(3px);
$px-4: decrease-by-10-percent(4px);
$px-5: decrease-by-10-percent(5px);
$px-6: decrease-by-10-percent(6px);
$px-7: decrease-by-10-percent(7px);
$px-8: decrease-by-10-percent(8px);
$px-9: decrease-by-10-percent(9px);
$px-10: decrease-by-10-percent(10px);
$px-11: decrease-by-10-percent(11px);
$px-12: decrease-by-10-percent(12px);
$px-13: decrease-by-10-percent(13px);
$px-13-87: decrease-by-10-percent(13.87px);
$px-14: decrease-by-10-percent(14px);
$px-15: decrease-by-10-percent(15px);
$px-16: decrease-by-10-percent(16px);
$px-17: decrease-by-10-percent(17px);
$px-18: decrease-by-10-percent(18px);
$px-19: decrease-by-10-percent(19px);
$px-20: decrease-by-10-percent(20px);
$px-21: decrease-by-10-percent(21px);
$px-22: decrease-by-10-percent(22px);
$px-23: decrease-by-10-percent(23px);
$px-24: decrease-by-10-percent(24px);
$px-25: decrease-by-10-percent(25px);
$px-26: decrease-by-10-percent(26px);
$px-27: decrease-by-10-percent(27px);
$px-28: decrease-by-10-percent(28px);
$px-29: decrease-by-10-percent(29px);
$px-30: decrease-by-10-percent(30px);
$px-31: decrease-by-10-percent(31px);
$px-32: decrease-by-10-percent(32px);
$px-33: decrease-by-10-percent(33px);
$px-34: decrease-by-10-percent(34px);
$px-35: decrease-by-10-percent(35px);
$px-36: decrease-by-10-percent(36px);
$px-37: decrease-by-10-percent(37px);
$px-38: decrease-by-10-percent(38px);
$px-39: decrease-by-10-percent(39px);
$px-40: decrease-by-10-percent(40px);
$px-41: decrease-by-10-percent(41px);
$px-42: decrease-by-10-percent(42px);
$px-43: decrease-by-10-percent(43px);
$px-44: decrease-by-10-percent(44px);
$px-45: decrease-by-10-percent(45px);
$px-46: decrease-by-10-percent(46px);
$px-47: decrease-by-10-percent(47px);
$px-48: decrease-by-10-percent(48px);
$px-49: decrease-by-10-percent(49px);
$px-50: decrease-by-10-percent(50px);
$px-51: decrease-by-10-percent(51px);
$px-52: decrease-by-10-percent(52px);
$px-53: decrease-by-10-percent(53px);
$px-54: decrease-by-10-percent(54px);
$px-55: decrease-by-10-percent(55px);
$px-56: decrease-by-10-percent(56px);
$px-57: decrease-by-10-percent(57px);
$px-58: decrease-by-10-percent(58px);
$px-59: decrease-by-10-percent(59px);
$px-60: decrease-by-10-percent(60px);
$px-61: decrease-by-10-percent(61px);
$px-62: decrease-by-10-percent(62px);
$px-63: decrease-by-10-percent(63px);
$px-64: decrease-by-10-percent(64px);
$px-65: decrease-by-10-percent(65px);
$px-66: decrease-by-10-percent(66px);
$px-67: decrease-by-10-percent(67px);
$px-68: decrease-by-10-percent(68px);
$px-69: decrease-by-10-percent(69px);
$px-70: decrease-by-10-percent(70px);
$px-71: decrease-by-10-percent(71px);
$px-72: decrease-by-10-percent(72px);
$px-73: decrease-by-10-percent(73px);
$px-74: decrease-by-10-percent(74px);
$px-75: decrease-by-10-percent(75px);
$px-76: decrease-by-10-percent(76px);
$px-77: decrease-by-10-percent(77px);
$px-78: decrease-by-10-percent(78px);
$px-79: decrease-by-10-percent(79px);
$px-80: decrease-by-10-percent(80px);
$px-81: decrease-by-10-percent(81px);
$px-82: decrease-by-10-percent(82px);
$px-83: decrease-by-10-percent(83px);
$px-84: decrease-by-10-percent(84px);
$px-85: decrease-by-10-percent(85px);
$px-86: decrease-by-10-percent(86px);
$px-87: decrease-by-10-percent(87px);
$px-88: decrease-by-10-percent(88px);
$px-89: decrease-by-10-percent(89px);
$px-90: decrease-by-10-percent(90px);
$px-91: decrease-by-10-percent(91px);
$px-92: decrease-by-10-percent(92px);
$px-93: decrease-by-10-percent(93px);
$px-94: decrease-by-10-percent(94px);
$px-95: decrease-by-10-percent(95px);
$px-96: decrease-by-10-percent(96px);
$px-97: decrease-by-10-percent(97px);
$px-98: decrease-by-10-percent(98px);
$px-99: decrease-by-10-percent(99px);
$px-100: decrease-by-10-percent(100px);
$px-101: decrease-by-10-percent(101px);
$px-102: decrease-by-10-percent(102px);
$px-103: decrease-by-10-percent(103px);
$px-104: decrease-by-10-percent(104px);
$px-105: decrease-by-10-percent(105px);
$px-106: decrease-by-10-percent(106px);
$px-107: decrease-by-10-percent(107px);
$px-108: decrease-by-10-percent(108px);
$px-109: decrease-by-10-percent(109px);
$px-110: decrease-by-10-percent(110px);
$px-111: decrease-by-10-percent(111px);
$px-112: decrease-by-10-percent(112px);
$px-113: decrease-by-10-percent(113px);
$px-114: decrease-by-10-percent(114px);
$px-115: decrease-by-10-percent(115px);
$px-116: decrease-by-10-percent(116px);
$px-117: decrease-by-10-percent(117px);
$px-118: decrease-by-10-percent(118px);
$px-119: decrease-by-10-percent(119px);
$px-120: decrease-by-10-percent(120px);
$px-121: decrease-by-10-percent(121px);
$px-122: decrease-by-10-percent(122px);
$px-123: decrease-by-10-percent(123px);
$px-124: decrease-by-10-percent(124px);
$px-125: decrease-by-10-percent(125px);
$px-126: decrease-by-10-percent(126px);
$px-127: decrease-by-10-percent(127px);
$px-128: decrease-by-10-percent(128px);
$px-129: decrease-by-10-percent(129px);
$px-130: decrease-by-10-percent(130px);
$px-131: decrease-by-10-percent(131px);
$px-132: decrease-by-10-percent(132px);
$px-133: decrease-by-10-percent(133px);
$px-134: decrease-by-10-percent(134px);
$px-135: decrease-by-10-percent(135px);
$px-136: decrease-by-10-percent(136px);
$px-137: decrease-by-10-percent(137px);
$px-138: decrease-by-10-percent(138px);
$px-139: decrease-by-10-percent(139px);
$px-140: decrease-by-10-percent(140px);
$px-141: decrease-by-10-percent(141px);
$px-142: decrease-by-10-percent(142px);
$px-143: decrease-by-10-percent(143px);
$px-144: decrease-by-10-percent(144px);
$px-145: decrease-by-10-percent(145px);
$px-146: decrease-by-10-percent(146px);
$px-147: decrease-by-10-percent(147px);
$px-148: decrease-by-10-percent(148px);
$px-149: decrease-by-10-percent(149px);
$px-150: decrease-by-10-percent(150px);
$px-151: decrease-by-10-percent(151px);
$px-152: decrease-by-10-percent(152px);
$px-153: decrease-by-10-percent(153px);
$px-154: decrease-by-10-percent(154px);
$px-155: decrease-by-10-percent(155px);
$px-156: decrease-by-10-percent(156px);
$px-157: decrease-by-10-percent(157px);
$px-158: decrease-by-10-percent(158px);
$px-159: decrease-by-10-percent(159px);
$px-160: decrease-by-10-percent(160px);
$px-161: decrease-by-10-percent(161px);
$px-162: decrease-by-10-percent(162px);
$px-163: decrease-by-10-percent(163px);
$px-164: decrease-by-10-percent(164px);
$px-165: decrease-by-10-percent(165px);
$px-166: decrease-by-10-percent(166px);
$px-167: decrease-by-10-percent(167px);
$px-168: decrease-by-10-percent(168px);
$px-169: decrease-by-10-percent(169px);
$px-170: decrease-by-10-percent(170px);
$px-171: decrease-by-10-percent(171px);
$px-172: decrease-by-10-percent(172px);
$px-173: decrease-by-10-percent(173px);
$px-174: decrease-by-10-percent(174px);
$px-175: decrease-by-10-percent(175px);
$px-176: decrease-by-10-percent(176px);
$px-177: decrease-by-10-percent(177px);
$px-178: decrease-by-10-percent(178px);
$px-179: decrease-by-10-percent(179px);
$px-180: decrease-by-10-percent(180px);
$px-181: decrease-by-10-percent(181px);
$px-182: decrease-by-10-percent(182px);
$px-183: decrease-by-10-percent(183px);
$px-184: decrease-by-10-percent(184px);
$px-185: decrease-by-10-percent(185px);
$px-186: decrease-by-10-percent(186px);
$px-187: decrease-by-10-percent(187px);
$px-188: decrease-by-10-percent(188px);
$px-189: decrease-by-10-percent(189px);
$px-190: decrease-by-10-percent(190px);
$px-191: decrease-by-10-percent(191px);
$px-192: decrease-by-10-percent(192px);
$px-193: decrease-by-10-percent(193px);
$px-194: decrease-by-10-percent(194px);
$px-195: decrease-by-10-percent(195px);
$px-196: decrease-by-10-percent(196px);
$px-197: decrease-by-10-percent(197px);
$px-198: decrease-by-10-percent(198px);
$px-199: decrease-by-10-percent(199px);
$px-200: decrease-by-10-percent(200px);
$px-201: decrease-by-10-percent(201px);
$px-202: decrease-by-10-percent(202px);
$px-203: decrease-by-10-percent(203px);
$px-204: decrease-by-10-percent(204px);
$px-205: decrease-by-10-percent(205px);
$px-206: decrease-by-10-percent(206px);
$px-207: decrease-by-10-percent(207px);
$px-208: decrease-by-10-percent(208px);
$px-209: decrease-by-10-percent(209px);
$px-210: decrease-by-10-percent(210px);
$px-211: decrease-by-10-percent(211px);
$px-212: decrease-by-10-percent(212px);
$px-213: decrease-by-10-percent(213px);
$px-214: decrease-by-10-percent(214px);
$px-215: decrease-by-10-percent(215px);
$px-216: decrease-by-10-percent(216px);
$px-217: decrease-by-10-percent(217px);
$px-218: decrease-by-10-percent(218px);
$px-219: decrease-by-10-percent(219px);
$px-220: decrease-by-10-percent(220px);
$px-221: decrease-by-10-percent(221px);
$px-222: decrease-by-10-percent(222px);
$px-223: decrease-by-10-percent(223px);
$px-224: decrease-by-10-percent(224px);
$px-225: decrease-by-10-percent(225px);
$px-226: decrease-by-10-percent(226px);
$px-227: decrease-by-10-percent(227px);
$px-228: decrease-by-10-percent(228px);
$px-229: decrease-by-10-percent(229px);
$px-230: decrease-by-10-percent(230px);
$px-231: decrease-by-10-percent(231px);
$px-232: decrease-by-10-percent(232px);
$px-233: decrease-by-10-percent(233px);
$px-234: decrease-by-10-percent(234px);
$px-235: decrease-by-10-percent(235px);
$px-236: decrease-by-10-percent(236px);
$px-237: decrease-by-10-percent(237px);
$px-238: decrease-by-10-percent(238px);
$px-239: decrease-by-10-percent(239px);
$px-240: decrease-by-10-percent(240px);
$px-241: decrease-by-10-percent(241px);
$px-242: decrease-by-10-percent(242px);
$px-243: decrease-by-10-percent(243px);
$px-244: decrease-by-10-percent(244px);
$px-245: decrease-by-10-percent(245px);
$px-246: decrease-by-10-percent(246px);
$px-247: decrease-by-10-percent(247px);
$px-248: decrease-by-10-percent(248px);
$px-249: decrease-by-10-percent(249px);
$px-250: decrease-by-10-percent(250px);
$px-251: decrease-by-10-percent(251px);
$px-252: decrease-by-10-percent(252px);
$px-253: decrease-by-10-percent(253px);
$px-254: decrease-by-10-percent(254px);
$px-255: decrease-by-10-percent(255px);
$px-256: decrease-by-10-percent(256px);
$px-257: decrease-by-10-percent(257px);
$px-258: decrease-by-10-percent(258px);
$px-259: decrease-by-10-percent(259px);
$px-260: decrease-by-10-percent(260px);
$px-261: decrease-by-10-percent(261px);
$px-262: decrease-by-10-percent(262px);
$px-263: decrease-by-10-percent(263px);
$px-264: decrease-by-10-percent(264px);
$px-265: decrease-by-10-percent(265px);
$px-266: decrease-by-10-percent(266px);
$px-267: decrease-by-10-percent(267px);
$px-268: decrease-by-10-percent(268px);
$px-269: decrease-by-10-percent(269px);
$px-270: decrease-by-10-percent(270px);
$px-271: decrease-by-10-percent(271px);
$px-272: decrease-by-10-percent(272px);
$px-273: decrease-by-10-percent(273px);
$px-274: decrease-by-10-percent(274px);
$px-275: decrease-by-10-percent(275px);
$px-276: decrease-by-10-percent(276px);
$px-277: decrease-by-10-percent(277px);
$px-278: decrease-by-10-percent(278px);
$px-279: decrease-by-10-percent(279px);
$px-280: decrease-by-10-percent(280px);
$px-281: decrease-by-10-percent(281px);
$px-282: decrease-by-10-percent(282px);
$px-283: decrease-by-10-percent(283px);
$px-284: decrease-by-10-percent(284px);
$px-285: decrease-by-10-percent(285px);
$px-286: decrease-by-10-percent(286px);
$px-287: decrease-by-10-percent(287px);
$px-288: decrease-by-10-percent(288px);
$px-289: decrease-by-10-percent(289px);
$px-290: decrease-by-10-percent(290px);
$px-291: decrease-by-10-percent(291px);
$px-292: decrease-by-10-percent(292px);
$px-293: decrease-by-10-percent(293px);
$px-294: decrease-by-10-percent(294px);
$px-295: decrease-by-10-percent(295px);
$px-296: decrease-by-10-percent(296px);
$px-297: decrease-by-10-percent(297px);
$px-298: decrease-by-10-percent(298px);
$px-299: decrease-by-10-percent(299px);
$px-300: decrease-by-10-percent(300px);
$px-310: decrease-by-10-percent(310px);
$px-318: decrease-by-10-percent(318px);
$px-320: decrease-by-10-percent(320px);
$px-336: decrease-by-10-percent(336px);
$px-325: decrease-by-10-percent(325px);
$px-350: decrease-by-10-percent(350px);
$px-375: decrease-by-10-percent(375px);
$px-376: decrease-by-10-percent(376px);
$px-380: decrease-by-10-percent(380px);
$px-400: decrease-by-10-percent(400px);
$px-412: decrease-by-10-percent(412px);
$px-434: decrease-by-10-percent(434px);
$px-440: decrease-by-10-percent(440px);
$px-465: decrease-by-10-percent(465px);
$px-448: decrease-by-10-percent(448px);
$px-488: decrease-by-10-percent(488px);
$px-500: decrease-by-10-percent(500px);
$px-520: decrease-by-10-percent(520px);
$px-530: decrease-by-10-percent(530px);
$px-535: decrease-by-10-percent(535px);
$px-550: decrease-by-10-percent(550px);
$px-580: decrease-by-10-percent(580px);
$px-588: decrease-by-10-percent(588px);
$px-600: decrease-by-10-percent(600px);
$px-632: decrease-by-10-percent(632px);
$px-635: decrease-by-10-percent(635px);
$px-650: decrease-by-10-percent(650px);
$px-660: decrease-by-10-percent(660px);
$px-665: decrease-by-10-percent(665px);
$px-688: decrease-by-10-percent(688px);
$px-700: decrease-by-10-percent(700px);
$px-712: decrease-by-10-percent(712px);
$px-730: decrease-by-10-percent(730px);

$px-800: decrease-by-10-percent(800px);
$px-840: decrease-by-10-percent(840px);
$px-848: decrease-by-10-percent(848px);
$px-884: decrease-by-10-percent(884px);
$px-900: decrease-by-10-percent(900px);
$px-942: decrease-by-10-percent(942px);
$px-991: decrease-by-10-percent(991px);
$px-999: decrease-by-10-percent(999px);
$px-1040: decrease-by-10-percent(1040px);
$px-1100: decrease-by-10-percent(1100px);
$px-1140: decrease-by-10-percent(1140px);
$px-1200: decrease-by-10-percent(1200px);
$px-1430: decrease-by-10-percent(1430px);
$px-9999: decrease-by-10-percent(9999px);
