
a:hover {
  text-decoration: none;
}

a.btn:hover,
a.btn:active,
a.btn:focus {
  text-decoration: none !important;
}

.profile_button {
  font-size: $px-14 !important;
  width: 100% !important;
}

a {
  color: #0a80ff;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: #0062e1;
}

label {
  display: inline-block;
  margin-bottom: 0.45rem;
}

.form-control {
  font-size: $px-14;
  border-radius: $px-5;
}

.form-group {
  margin-bottom: 0.9rem;
}

.btn-icon {
  margin-right: 0.45rem;
}

.tablist {
  display: flex;
  display: -webkit-flex;
  cursor: pointer;
}

.first_list {
  display: flex;
  display: -webkit-flex;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  padding: $px-20;
  border-radius: 0.45rem;
}

.dropdown-menu {
  padding: 0;
}

.dropdown-menu .dropdown-divider {
  margin: 0;
}

.dropdown-menu .dropdown-item:hover:first-child {
  border-radius: $px-5 $px-5 0 0;
}

.dropdown-menu .dropdown-item:hover:last-child {
  border-radius: 0px 0px $px-5 $px-5;
}

.header_image {
  width: $px-195;
}

.chat_avator_img {
  position: relative;
}

.group_img {
  width: $px-40;
  height: $px-40;
  object-fit: cover;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.dreams_chat {
  width: $px-40;
  height: $px-40;
  object-fit: cover;
}

.voice_pop {
  padding-right: $px-17;
}

.status_carousel {
  margin-top: $px-50;
}

.carousel_img {
  width: $px-650;
  height: $px-434;
}

.video_content {
  min-height: $px-200;
  margin-top: $px-70;
}

.voice-call-content {
  min-height: $px-200;
}

.chatimage img {
  width: $px-120;
  height: $px-120;
}

.send-btn_status {
  width: $px-52;
  height: $px-52;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background-color: #ffa977 !important;
  margin-left: $px-15;
  font-size: $px-22;
}

.status_telegram {
  color: #fff;
}

.media-lists {
  display: flex;
  justify-content: flex-start;
  margin: 0 decrease-by-10-percent(-5px) 0px;
  flex-wrap: wrap;
}

.media-lists .media-image {
  margin: $px-5;
  flex-basis: $px-74;
  min-width: $px-74;
}

.media-lists .media-image img {
  max-width: 100%;
}

.list-group-item {
  background-color: inherit;
  border-color: #ebebeb;
}

ul.list-inline .list-inline-item {
  margin-bottom: 0px !important;
}

.form-control:focus {
  box-shadow: none;
  border-color: #e6e6e6;
}

.custom-control-label {
  line-height: $px-25;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0a80ff;
}

.dropdown-menu .dropdown-item {
  font-size: $px-14;
  cursor: pointer;
  padding: $px-7 $px-14;
}

.dropdown-menu .dropdown-item.active {
  background: none;
  color: inherit;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active {
  background-color: #e6e6e6;
  color: black;
}

.open_drop {
  color: #fff !important;
}

.send-btn_status:focus {
  box-shadow: none !important;
  border-color: #ffa977 !important;
}

.avatar {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  border-radius: 50%;
  color: #417eb8;
  font-size: $px-16;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -$px-0-3;
  text-align: center;
  line-height: $px-44;
  height: $px-44;
  width: $px-44;
  background-color: #fff;
  .avatar-title {
    color: rgba(255, 255, 255, 0.8);
    background-color: #650681;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: $px-19;
  }
  a {
    width: 100%;
    height: 100%;
    display: block;
    transition: color 0.3s;
    color: #0a80ff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.avatar-sm {
    height: 1.17rem;
    width: 1.17rem;
    .avatar-title {
      font-size: $px-14;
    }
  }
  &.avatar-lg {
    height: 5.49rem;
    width: 5.49rem;
    .avatar-title {
      font-size: $px-29;
    }
  }
  &.avatar-xl {
    height: 5.49rem;
    width: 5.49rem;
  }
}

.header_drop_icon {
  border-radius: $px-6;
}

.accordion-col .accordion-title {
  cursor: pointer;
  padding: $px-15 0 $px-8;
}

.accordion-col .accordion-title .primary-title i {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.accordion-col .accordion-title.active .primary-title i {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
}

.accordion-col .accordion-content {
  border-bottom: $px-1 solid #e6e6e6;
  padding-bottom: $px-15;
  /* display: none; */
}

.accordion-col .accordion-content:last-child {
  border-bottom: 0;
}

.accordion-col .accordion-content h6 {
  font-size: $px-14;
}

/*-----------------
  3. Avatar
-----------------------*/

.avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #650681;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar .border {
  border-width: $px-3 !important;
}

.avatar .rounded {
  border-radius: $px-6 !important;
}

.avatar-xs {
  width: 1.485rem;
  height: 1.485rem;
}

.avatar-xs .border {
  border-width: $px-2 !important;
}

.avatar-xs .rounded {
  border-radius: $px-4 !important;
}

.avatar-xs .avatar-title {
  font-size: $px-12;
}

.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
  border-width: $px-1;
}

.avatar-sm {
  width: 2.25rem;
  height: 2.25rem;
  &.avatar-away {
    &::before {
      border-width: $px-2;
    }
  }
  &.avatar-offline {
    &::before {
      border-width: $px-2;
    }
  }
  &.avatar-online {
    &::before {
      border-width: $px-2;
    }
  }

  .border {
    border-width: $px-3 !important;
  }
  .rounded {
    border-radius: $px-4 !important;
  }
  .avatar-title {
    font-size: $px-15;
  }
}

.avatar-lg {
  width: 3.375rem;
  height: 3.375rem;
}

.avatar-lg .border {
  border-width: $px-3 !important;
}

.avatar-lg .rounded {
  border-radius: $px-8 !important;
}

.avatar-lg .avatar-title {
  font-size: $px-24;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: $px-3;
}

.avatar-xl {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar-xl .border {
  border-width: $px-4 !important;
}

.avatar-xl .rounded {
  border-radius: $px-8 !important;
}

.avatar-xl .avatar-title {
  font-size: $px-28;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: $px-4;
}

.avatar-xxl {
  width: 4.6125rem;
  height: 4.6125rem;
}

.avatar-xxl .border {
  border-width: $px-6 !important;
}

.avatar-xxl .rounded {
  border-radius: $px-8 !important;
}

.avatar-xxl .avatar-title {
  font-size: $px-30;
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: $px-4;
}

.avatar-group {
  display: flex;
  display: -webkit-flex;
  padding-left: $px-15;
  margin: 0 auto 0 0;
}

.avatar-group .avatar + .avatar {
  margin-left: -0.675rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.365625rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.5625rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -0.9rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.153125rem;
}

.avatar-group .avatar:hover {
  z-index: 0 !important;
}

.avatar-group.group-col {
  display: block;
}

.avatar-group.group-col > div {
  display: flex;
  display: -webkit-flex;
  padding-bottom: $px-5;
}

.avatar-group.group-col .avatar {
  margin-right: 0;
}

.contact-close {
  float: right;
  left: 95%;
  right: 0px;
  position: relative;
  top: 5%;
}

.status_contactclose {
  float: right;
  left: 95%;
  right: 0px;
  position: relative;
  top: 1%;
}

.status-active {
  border: $px-4 solid #650681;
}

.avatar.avatar-xl .avatar-title {
  font-size: $px-39;
}

.nav.nav-tabs {
  border: none;
  line-height: 2;
}

.badge {
  padding: $px-5 $px-10;
  font-size: $px-11;
}

.badge.badge-success {
  background-color: #0abb87;
}

.badge.badge-danger {
  background-color: #fd397a;
}

.badge.badge-secondary {
  background-color: dimgray;
}

.badge.badge-info {
  background-color: #00bcd4;
}

.badge.badge-warning {
  background-color: #ffb822;
}

.badge.badge-dark {
  background-color: #3a3f51;
}

.badge.badge-primary {
  background-color: #0a80ff;
}

.badge.badge-light {
  background-color: #e6e6e6;
}

.btn {
  position: relative;
  border-color: transparent;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn .badge {
  padding: $px-2 $px-6;
  right: $px-7;
  top: -$px-7;
  position: absolute;
}

.input-group .input-group-text {
  font-size: $px-14;
}

.tooltip .arrow {
  display: none;
}

.tooltip .tooltip-inner {
  background-color: rgba(0, 0, 0, 0.5);
}

.sticky-top {
  z-index: 0 !important;
}

.main-wrapper {
  height: calc(90vh + $px-8);

  display: flex;
  display: -webkit-flex;
  overflow-x: hidden;
  flex-direction: column;
}

.main-wrapper .content {
  display: flex;
  display: -webkit-flex;
  height: 100%;
  z-index: 0 !important;
}

.main-wrapper .content .sidebar-group {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  position: relative;
}

.main-wrapper .content .sidebar-group .sidebar {
  background-color: #f7fbfe;
  overflow: hidden;
  width: $px-465;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-wrapper .content .sidebar-group .sidebar:not(.active) {
  display: none;
}

.header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: $px-24;
  margin-bottom: 0px;
  font-weight: 600;
  margin-top: $px-14;
}

.header-top .logo {
  margin-top: 0 !important;
}

.header-top > span {
  font-size: $px-22;
}

.header-top ul {
  margin-bottom: 0;
  display: flex;
}

.chat-header ul {
  margin: 0 decrease-by-10-percent(-8px);
}

.chat-header ul li:not(.list-inline-item) {
  margin-bottom: 0;
}

.chat-header ul li:last-child {
  margin-right: 0 !important;
}

.sidebar-body {
  flex: 1;
  overflow: auto;
  padding: 0px $px-15;
  width: 100%;
  background-color: #f7fbfe !important;
}

.main-wrapper .content .sidebar-group .sidebar .ng-scrollbar {
  flex: 1;
}

.main-wrapper .content .sidebar-group .sidebar .ng-scroll-content {
  display: block;
}

.sidebar-body .profile-name {
  font-weight: 600;
}

.close_profile4 {
  color: #000;
  border-color: #e6e6e6;
  margin-top: $px-4 !important;
}

.sidebar .user-list li {
  padding: $px-20 $px-16;
  display: flex;
  display: -webkit-flex;
  cursor: pointer;
  border-radius: $px-10;
  background-color: #fff;
  margin-bottom: $px-10;
  position: relative;
  border: $px-0-5 solid rgba(177, 220, 255, 0.5);
}

.sidebar .user-list li.active,
.sidebar .user-list li.active:hover {
  background-color: #417eb8 !important;
}

.sidebar .user-list li.active .users-list-body h5 {
  color: #ffffff !important;
}

.sidebar .user-list li.active .users-list-body p {
  color: #ffffff !important;
}

.sidebar .user-list li.active .btn-chat-action {
  background-color: #d9f2ff;
}

.sidebar .user-list li.active .time-muted-text {
  color: #ffffff !important;
}

.sidebar .user-list li.unread .users-list-body p {
  color: #fff;
}

.sidebar .user-list li figure {
  margin-right: 0.9rem;
}

.sidebar .user-list li .users-list-body {
  flex: 1;
  position: relative;
  min-width: 0px;
  display: flex;
}

.sidebar .user-list li .users-list-body > div:first-child {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sidebar .user-list li .users-list-body h5 {
  color: #334155;
  font-size: $px-16;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 0px;
}

.sidebar .user-list li .users-list-body p {
  color: #64748b;
  font-size: $px-14;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0px;
}

.sidebar .user-list li .users-list-body .last-chat-time {
  padding-left: $px-15;
  position: relative;
}

.sidebar
  .user-list
  li
  .users-list-body
  .last-chat-time
  [data-toggle="dropdown"]
  i {
  font-size: $px-18;
}

.sidebar .user-list li .users-list-body .last-chat-time .new-message-count {
  width: $px-8;
  height: $px-8;

  line-height: 0;
  font-size: $px-13;
  background-color: #ffa977;
  color: #fff;
  border-radius: 50%;
  margin-left: auto;
  box-shadow: 0 $px-6 $px-12 rgba(0, 0, 0, 0.08);
  margin-top: $px-10;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

.sidebar .user-list li .users-list-body .last-chat-time .chat-toggle {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: right;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  width: $px-18;
  height: $px-24;
}

.sidebar .user-list li:hover .last-chat-time .chat-toggle {
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

.sidebar .user-list li:hover {
  border-radius: $px-10;
  border: $px-0-5 solid rgba(177, 220, 255, 0.5) !important;
  background-color: #d9f2ff !important;
}

.sidebar .user-list li:hover .new-message-count {
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

.new-message {
  width: $px-8;
  height: $px-8;
  line-height: 0;
  font-size: $px-13;
  background-color: #ffa977;
  color: #fff;
  border-radius: 50%;
  margin-left: auto;
  box-shadow: 0 $px-6 $px-12 rgba(0, 0, 0, 0.08);
  display: inline-block;
}

.btn-chat-action {
  color: rgba(100, 116, 139, 1);
  border: none;
  background: transparent;
  padding: 0px;
  border-radius: $px-999;
  width: $px-24;
  height: $px-24;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -$px-6;
}

.btn-chat-action:hover {
  background-color: #fff;
}

.msg-stutas {
  height: $px-20;
  padding: $px-2 $px-6;
  border-radius: $px-6;
  background-color: #d9f2ff !important;
  color: #417eb8;
  text-align: center;
  font-size: $px-12;
  font-weight: 400;
  line-height: $px-18;
  letter-spacing: -$px-0-2;
}

.msg-stutas-Archived {
  height: $px-20;
  padding: $px-2 $px-6;
  border-radius: $px-6;
  background-color: #f1f5f9 !important;
  color: #64748b;
  text-align: center;
  font-size: $px-12;
  font-weight: 400;
  line-height: $px-18;
  letter-spacing: -$px-0-2;
}

.time-muted-text {
  color: #9da4b0 !important;
  font-size: $px-12;
}

.msg-info {
  display: flex;
  gap: $px-8;
  align-items: center;
}

.btn-chat-action::after {
  display: none;
}

.sidebar .user-list li.unread h5 {
  color: #fff;
}

.sidebar .user-list li.unread p {
  color: #fff;
}

.sidebar .user-list li.unread .text-muted {
  color: rgba(255, 255, 255, 0.4) !important;
}

.rightside_tab {
  padding-top: $px-5 !important;
}

.chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: $px-1 solid #e6e6e6;
  border-left: $px-1 solid #e6e6e6;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  width: 100%;
}

.user_callog {
  display: flex;
  margin-top: 0px;
}

i.fas.fa-share {
  color: #009688;
  line-height: 1;
}

i.fas.fa-reply {
  color: #ff111b;
}

.calllog_p {
  margin-left: $px-5;
}

.header_button {
  position: absolute;
  right: $px-5;
  width: $px-40;
  height: $px-40;
  border-radius: $px-20;
  font-size: $px-14;
  color: white;
  text-align: center;
  background-color: #ffa977;
  bottom: $px-40;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ffa977;
  border-color: #ffa977;
}

.right_sidebar_profile {
  margin-top: -$px-15;
}

.account_details,
.security_details {
  background-color: #edeef6;
  border-color: #d9d7d8;
  border-radius: $px-5 !important;
  border-bottom: 0 !important;
}

.close_icon {
  font-size: $px-18;
}

.sidebar-body ul li:hover {
  background-color: #f9f9f9;
}

.button_plus {
  margin-left: 0px;
}

.chat .chat-header {
  display: flex !important;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  border-bottom: $px-1 solid #e6e6e6;
  align-items: center;
  height: $px-90;
  padding-top: 0px;
}

.main_content {
  width: 100% !important;
}

.chat .chat-header .user-details {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.chat .chat-header .user-details figure {
  margin-right: 0.9rem;
}

.chat .chat-header .user-details h5 {
  color: #334155;
  font-size: $px-16;
  font-style: normal;
  font-weight: 600;
  line-height: $px-28;
  letter-spacing: -$px-0-3;
  margin-bottom: 0px !important;
}

.msg-header-Category {
  color: #64748b;
  /* Body Text 5/Regular */
  font-size: $px-14;
  font-style: normal;
  font-weight: 400;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
}

.right_sidebar_icon {
  list-style: none;
}

.chat .chat-header .chat-options ul {
  margin-bottom: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.btn.btn-outline-light:hover {
  background-color: #e6e6e6;
}

.chat .chat-body {
  flex: 1;
  padding: $px-20;
}

.chat .chat-body:not(.no-message) {
  overflow: auto;
}

.chat .chat-body.no-message {
  user-select: none;
}

.chat .chat-body .messages {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

.chat .chat-body .messages .chats {
  display: flex;

  max-width: 100%;
  margin-bottom: 0.9rem;
  &.chats-right {
    margin-left: auto;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.chats-right .chat-time {
    justify-content: flex-end;
  }
  &.chats-right .message-content {
    background-color: #5e92cb;
    color: #fff;
  }
  &.chats-right .message-content .message-time {
    color: #d9f2ff;
  }
  .message-content {
    border-radius: $px-18;
    background-color: #f1f5f9;
    padding: $px-6 $px-12;
    font-size: $px-14;
    color: #475569;
    min-width: $px-276;
  }
  .chat-time {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    margin-bottom: 0.9rem;
    .avatar {
      margin-right: 0.9rem;
    }
    h5 {
      font-size: $px-15;
      margin-bottom: 0;
    }
    .time {
      color: #828282;
      margin-top: $px-2;
      font-size: $px-12;
      i img {
        width: $px-14;
        height: auto;
        margin-left: $px-2;
        vertical-align: 0px;
      }
    }
  }
}

.chat-avatar {
  padding: $px-8;
}

.message-title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.message-sender-name {
  color: #475569;
  font-size: $px-14;
  font-style: normal;
  font-weight: 600;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
}

.message-time {
  color: #94a3b8;
  font-size: $px-11;
  font-weight: 400;
  line-height: 160%;
}

.chat
  .chat-body
  .messages
  .chats.chats-right
  .message-content
  .message-sender-name {
  color: #fff;
}

.chat-line {
  margin: $px-7 0 $px-20;
  position: relative;
  text-align: center;
  width: 100%;
  border-top: solid #e2e8f0 $px-2;
}

.chat-line.new-msg::after {
  content: "New";
  bottom: $px-24;
  position: absolute;
  right: 0;
  color: #fbbf24;
  background-color: #fff;
  padding-left: $px-10;
  font-size: $px-11;
}

.chat-line.new-msg {
  border-top: solid #fef3c7 $px-2;
}

.chat-date {
  color: #475569;
  font-size: $px-11;
  display: inline-block;
  padding: $px-8 $px-12;
  border-radius: $px-6;
  border: $px-2 solid #e2e8f0;
  background-color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: $px-13;
  letter-spacing: 0.0$px-6;
  position: relative;
  bottom: $px-17;
}

.chat .chat-footer {
  padding: $px-16;

  align-self: stretch;
  border-top: $px-1 solid #e2e8f0;
  background-color: #f7fbfe;
}

.chat .chat-footer form {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.chat .chat-footer form .form-buttons {
  -webkit-align-items: center;
  display: flex;
  align-items: center;
}

.chat .chat-footer form .form-buttons .btn {
  margin-left: 0px;
  color: #bdbfc7;
  font-size: $px-20;
  padding: 0;
  width: $px-20;
  height: $px-20;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: $px-10;
}

.chat .chat-footer form .form-buttons .btn:last-child {
  margin-right: 0;
}

.chat + .sidebar-group {
  margin-right: 0px;
}

.chat + .sidebar-group .sidebar {
  margin-right: 0px;
}

.chat-header {
  /* background-image: url('../img/top-curve-bg.png'); */
  position: relative;
  width: 100%;
  overflow: initial;
  padding: $px-12 $px-24;
  background-color: #ffffff;
  padding-bottom: 0px !important;
}

.Messaging-title {
  color: #334155;
  font-size: $px-18;
  font-weight: 600;
  line-height: $px-40;
}

.btn-Add-msg {
  font-size: $px-14;
  width: auto;
  display: inline-flex;
  line-height: $px-14;
  border-radius: $px-10;
  background-color: #185b8d;
  border: $px-1 solid #185b8d;
  color: #fff;
  padding: $px-9 !important;
  box-shadow: inset 0px $px-2 0px 0px rgba(250, 250, 250, 0.2);
}

.btn-Add-msg:hover,
.btn-Add-msg:focus,
.btn-Add-msg:active {
  background-color: #154876 !important;
  box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
  color: #fff;
}

.btn-Add-msg:disabled {
  background-color: #b1dcff !important;
  border: $px-1 solid #b1dcff !important;
}

.btn-Add-msg svg-icon svg g g path {
  fill: #ffffff;
}

.nav.nav-tabs.chat-tabs .nav-item .nav-link {
  color: rgba(100, 116, 139, 1);
  font-size: $px-14;
  font-weight: 400;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  padding: $px-8;
  background-color: transparent;
  border: none;
}

.nav.nav-tabs.chat-tabs .nav-item .nav-link.active {
  color: #004876;
  text-align: center;
  font-size: $px-14;
  font-weight: 600;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  border-radius: $px-8 $px-8 0px 0px;
  background-color: #f6f9fc;
}

.header-top ul li a {
  color: #000;
  font-size: $px-18;
}

.sidebar .list-group-item.unread h5 {
  color: #fff;
}

.sidebar .list-group-item.unread p {
  color: #fff;
}

.sidebar .list-group-item.unread .text-muted {
  color: rgba(255, 255, 255, 0.4) !important;
}

.chat .chat-footer form .form-buttons .btn.send-btn {
  width: $px-40;
  height: $px-40;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $px-10;
  background-color: #417eb8;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover {
  background-color: #154876 !important;
  box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2) !important;
  color: #fff;
  border-color: #154876 !important;
}

.chat .chat-footer form .form-buttons .btn.send-btn svg-icon svg g path {
  fill: #ffffff !important;
}

.chat .chat-footer form .form-buttons .btn.voice-btn {
  width: $px-40;
  height: $px-40;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $px-10;
}

.chat .chat-footer form .form-buttons .btn.voice-btn:hover {
  background-color: #d9f2ff;
}

.chat .chat-footer form .form-buttons .btn.attachment-btn:hover {
  background-color: #d9f2ff;
}

.attachment-btn::after {
  display: none !important;
}

.chat .chat-footer form .form-buttons .btn.attachment-btn {
  width: $px-40;
  height: $px-40;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $px-10;
}

.header-action {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header-action li {
  display: inline-block;
  margin-left: $px-10;
}

.header-action li a {
  color: #1b1a1a;
  font-size: $px-18;
}

.user-list {
  border-radius: 0.225rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.user-list li {
  padding: $px-17 $px-20;
  display: flex;
  display: -webkit-flex;
  cursor: pointer;
  border-radius: $px-6;
  background-color: #fff;
  margin-bottom: $px-10;
  border: 0;
  box-shadow: 0 $px-2 $px-8 rgba(0, 0, 0, 0.08);
}

.user-list li .avatar {
  margin-right: 0.9rem;
  display: inline-block;
  margin-bottom: 0;
  border-radius: 50%;
  position: relative;
  color: #417eb8;
  font-size: $px-16;
  font-style: normal;
  font-weight: 500;

  letter-spacing: -$px-0-3;
  text-align: center;
  line-height: $px-44;
  height: $px-44;
  width: $px-44;
  background-color: #fff;
}

.position {
  position: fixed !important;
}

.msg-dropdowm-action::before {
  content: "\A";
  border-style: solid;
  border-width: $px-10 $px-15 $px-10 0;
  border-color: transparent rgba(248, 250, 252, 1) transparent transparent;
  position: absolute;
  left: $px-5;
  transform: rotate(90deg);
  top: -$px-3;
}
.msg-dropdowm-action {
  border-radius: $px-10 !important;
  border: none;
  box-shadow: 0 0 $px-18 $px-9 rgba(45, 54, 67, 0.12);
  margin-top: $px-9;
  .dropdown-item {
    border-bottom: solid $px-1 #e2e8f0;
    background-color: #f8fafc;
    height: $px-40;
    padding: $px-8 $px-16 $px-8 $px-12;
    color: #334155;
    font-size: $px-14;
    font-weight: 400;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
    position: relative;
    svg-icon {
      margin-right: $px-8;
    }
    &:hover {
      background-color: #f1f5f9;
    }
    &:first-child {
      border-top-left-radius: $px-10 !important;
      border-top-right-radius: $px-10 !important;
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: $px-10 !important;
      border-bottom-right-radius: $px-10 !important;
    }
  }
}

.msg-attachemnt-dropdowm-action::before {
  content: "\A";
  border-style: solid;
  border-width: $px-10 $px-15 $px-10 0;
  border-color: transparent rgb(255 255 255) transparent transparent;
  position: absolute;
  right: $px-9;
  transform: rotate(270deg);
  bottom: -$px-13;
}
.msg-attachemnt-dropdowm-action {
  border-radius: $px-10 !important;
  border: none;
  box-shadow: 0 0 $px-18 $px-9 rgba(45, 54, 67, 0.12);
  margin-top: $px-9;
  .dropdown-item {
    background-color: #f8fafc;
    height: $px-40;
    padding: $px-8 $px-16 $px-8 $px-12;
    color: #334155;
    font-size: $px-14;
    font-weight: 400;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
    position: relative;

    &:first-child {
      border-top-left-radius: $px-10 !important;
      border-top-right-radius: $px-10 !important;
    }

    &:last-child {
      border-bottom-left-radius: $px-10 !important;
      border-bottom-right-radius: $px-10 !important;
    }
  }
}
.msg-dropdowm-attachemnt-action .dropdown-item svg-icon {
  margin-right: $px-8;
}
.msg-dropdowm-attachemnt-action .dropdown-item {
  border-bottom: solid $px-1 #e2e8f0;
}

.msg-dropdowm-attachemnt-action .dropdown-item:hover {
  background-color: #f1f5f9;
}
.msg-dropdowm-attachemnt-action .dropdown-item:last-child {
  border-bottom: none;
}
.msg-attachemnt-dropdowm-action button svg-icon {
  padding: $px-2 !important;
}
.msg-attachemnt-dropdowm-action button.dropdown-item svg-icon svg path {
  fill: rgba(148, 163, 184, 1);
}
.msg-attachemnt-dropdowm-action button.dropdown-item:hover {
  background-color: rgba(65, 126, 184, 1) !important;
  color: #ffffff !important;
  svg-icon svg path {
    fill: rgba(217, 242, 255, 1);
  }
}

.chat_form {
  margin-right: $px-20;

  height: $px-40 !important;
  padding: $px-4 $px-4 $px-4 $px-12;
  border-radius: $px-10;
  border: $px-1 solid rgba(177, 220, 255, 0.5);
  background-color: #fff;
}

.user-list .avatar > a > img,
.user-list .avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-close a {
  color: #292f4c;
  opacity: 0.5;
  border-color: #e6e6e6;
}

.main-wrapper .content .sidebar-group.right-sidebar .sidebar {
  width: $px-310;
}

.primary-title {
  color: #ffa977;
  font-size: $px-14;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.primary-title i {
  font-size: $px-14;
}

.contact-action {
  padding: 0;
  margin: 0;
  list-style: none;
}

.contact-action li {
  padding: $px-11 0;
  border-bottom: $px-1 solid #f1f1f1;
}

.contact-action li a {
  color: #181c2f;
  font-size: $px-14;
  font-weight: bold;
}

.contact-action li.report-contact a,
.contact-action li.delete-chat a,
.contact-action li.sign-out a {
  color: #f00;
}

.left-sidebar {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

.left-sidebar-wrap {
  background-color: #fbfbfb;
  overflow: hidden;
  width: 448px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  flex: 1;
}

.right-sidebar {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  background: linear-gradient(#ffffff, #f8f8f8, #ffffff);
  opacity: 100%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  position: relative;
  width: $px-320;
}

.right-sidebar.show-right-sidebar {
  margin-right: 0;
}

.right-sidebar.hide-right-sidebar {
  margin-right: -$px-320;
}

.list_group_notread {
  color: #ffa977 !important;
}

.group_header {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.last-chat-time i.missed-col {
  width: $px-17;
  display: inline-block;
  margin-top: $px-10;
}

.last-chat-time i.missed-col img {
  max-width: 100%;
  height: auto;
}

/*-----------------
  4. Nav Tabs
-----------------------*/
.nav-tabs {
  border-bottom: $px-1 solid #f0f0f0;
}

.card-header-tabs {
  border-bottom: 0;
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #333;
  border-radius: 0;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #272b41;
}

.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #650681;
  color: #fff;
}

.left_newgroup {
  width: 100% !important;
  height: 100%;
  padding: $px-10;
}

.newgroup_search {
  color: #7f808c;
  background-color: #edeef6;
  height: $px-42;
  padding: $px-10;
  font-size: $px-14;
  line-height: 1.6;
  border-radius: 0.1265625rem;
  border-color: #edeef6;
}

.newgroup_search_btn {
  background-color: #660881;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.newgroup_ul {
  margin-top: $px-13;
}

.newgroup_ul li {
  border-bottom: $px-1 solid #edeef6;
}

.newgroup_ul li a.active {
  background-color: #680a83 !important;
  border-color: transparent;
  color: #fff !important;
  border-radius: $px-5 $px-5 0 0 !important;
}

.group_formcontrol {
  background-color: #edeef6;
  background-clip: padding-box;
  border: $px-1 solid #edeef6;
  height: $px-42;
  padding: $px-10;
  font-size: $px-14;
  line-height: 1.6;
  border-radius: 0.3375rem;
}

.group_control_text {
  background-color: #edeef6;
  border: $px-1 solid #edeef6;
}

.button_group {
  background-color: #edeef6;
  color: #000;
}

.carousel .carousel-inner {
  max-width: $px-650;
  width: 100%;
  margin: $px-50 auto 0;
}

.carousel .carousel-inner img {
  max-width: 100%;
  height: auto;
}

.carousel .carousel-item {
  width: 100%;
}

.group_card_mb {
  background-color: #edeef6;
  border-color: #fff;
}

.group_card_media {
  margin-left: -$px-30;
}

.group_image {
  border-radius: $px-35;
}

.header_button:hover {
  color: #fff;
  box-shadow: none;
}

.header_button:focus {
  box-shadow: none;
}

.newgroup_create {
  padding: $px-10 $px-20;
  line-height: 1.6;
  border-radius: 0.3375rem;
  background-color: #680a83;
  box-shadow: none;
  border-color: #680a83;
  margin: $px-7 auto $px-15;
  color: #fff;
  font-size: $px-14;
  text-align: center;
}

.newgroup_create:hover {
  background-color: #ffa977;
  color: #fff;
  border-color: #ffa977;
}

.newgroup_create:focus {
  outline: 0;
  box-shadow: none;
}

.newgroup_fa_search {
  font-size: $px-15;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.profile_Card {
  background-color: #edeef6;
  background-clip: border-box;
  border: $px-1 solid rgba(245, 246, 250, 0.85);
  border-radius: 0.3375rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  color: #000;
}

.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: transparent;
}

.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: $px-50;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: $px-50;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: $px-50;
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: $px-1 solid #ddd;
}

.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: $px-2 0 0 0;
}

.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}

.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: $px-2 0 0 0;
}

.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}

.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: $px-1;
}

.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #650681;
}

.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: $px-2;
  border-bottom-color: #650681;
  background-color: transparent;
  transition: none 0s ease 0s;
}

.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}

.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}

.nav-tabs-justified.nav-tabs-top {
  border-bottom: $px-1 solid #ddd;
}

.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: $px-2 0 0 0;
}

.nav-tabs.nav-tabs-solid.profile-tabs > li + li {
  margin-left: $px-20;
}

.nav-tabs.nav-tabs-solid.profile-tabs > li > a {
  border: $px-1 dashed #650681;
  font-size: $px-14;
  font-weight: bold;
  color: #650681;
  padding: $px-5 $px-10;
}

.nav-tabs.nav-tabs-solid.profile-tabs > li > a.active {
  color: #fff;
}

.view-more a {
  color: #bec0c9;
  font-size: $px-14;
}

.tab-content {
  padding-top: $px-20;
  .form-item {
    margin-bottom: 0.72rem;
  }
  .list-group-item {
    padding-left: 0;
    .text-truncate {
      font-size: $px-12;
    }
    span {
      font-size: $px-12;
    }
  }
}

.modal-header {
  padding: $px-20 $px-15;
  background-color: #680a83;
  color: #fff;
  border: transparent;
}

.modal-header .modal-title {
  font-size: $px-18;
  font-weight: 600;
}

.modal-content {
  border: transparent;
}

.modal-header .close {
  color: #fff;
  text-shadow: none;
  opacity: 1;
  background: transparent;
  border: transparent;
}

.create-group-members .group_card_mb .card-body {
  padding: $px-15;
  border-radius: $px-8;
}

.create-group-members .group_card_mb .card-body + label {
  margin: 0;
}

/*-----------------
  5. Status
-----------------------*/

.status-title {
  font-size: $px-16;
  font-weight: 600;
  color: #888;
  margin-bottom: $px-20;
}

.carousel-indicators li {
  border: 0;
  background-color: rgba(24, 28, 47, 0.32);
  width: $px-165;
  height: $px-10;
  border-radius: $px-50;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: $px-160;
  height: 0px;
  padding: 0;
  margin-right: $px-3;
  margin-left: $px-3;
  text-indent: -$px-999;
  cursor: pointer;
  background-color: rgb(255 255 255 / 73%);
  background-clip: unset;
  border: 0;
  border-top: $px-5 solid transparent;
  border-bottom: $px-5 solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-indicators li.active {
  background-color: rgb(252 122 46);
}

.status-message {
  display: flex;
  align-items: center;
  width: decrease-by-10-percent(770px);
  margin: $px-50 auto 0;
}

.status-message .form-control {
  background-color: rgba(24, 28, 47, 0.32);
  border-radius: $px-50;
  border: 0;
  height: $px-52;
  padding: $px-10 $px-20;
  margin-right: $px-15;
  color: #fff;
}

.status-message .form-buttons .btn.send-btn {
  width: $px-52;
  height: $px-52;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background-color: #ffa977;
  font-size: $px-22;
  box-shadow: unset;
}

.status-modal button.close {
  font-size: $px-34;
  margin: $px-20 $px-20 $px-20 auto;
  text-align: right;
  width: $px-34;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

body.custom-model-open .main-wrapper {
  -webkit-filter: blur($px-32);
  -moz-filter: blur($px-32);
  -ms-filter: blur($px-32);
  -o-filter: blur($px-32);
  filter: blur($px-32);
}

body.custom-model-open .modal-backdrop.show {
  background-color: #fff;
  opacity: 0.2;
}

.status-modal .modal-dialog {
  max-width: 100%;
  padding: $px-20;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}

.status-modal .modal-dialog .modal-content {
  border: 0;
  background-color: transparent;
  height: 100%;
  flex-direction: inherit;
  -webkit-flex-direction: inherit;
  align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.status-modal .modal-dialog .modal-content .inner-popup {
  margin: 0 auto;
}

.status-modal .custom-status-close {
  text-align: right;
  opacity: 1;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  text-shadow: none;
  z-index: 0 !important;
  color: #fff;
  font-size: $px-20;
}

.status-modal .carousel-indicators {
  top: -$px-30;
}

.bottom-message-col {
  max-width: $px-840;
  width: 100%;
  margin: 0 auto;
}

/*-----------------
  6. Call Logs
-----------------------*/
.call-card {
  border: 0;
  margin-bottom: $px-30;
  box-shadow:
    0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 $px-1 rgba(10, 10, 10, 0.02);
}

.call-log-header h4 {
  color: #ffa977;
  font-weight: 600;
  font-size: $px-16;
  margin-bottom: $px-20;
}

.call-log-header .clear-all {
  color: #8b8d97;
  font-size: $px-16;
  font-weight: 600;
}

.call-view-more {
  text-align: center;
  margin-bottom: $px-30;
  margin-top: -$px-45;
  z-index: 0 !important;
  position: relative;
}

.callog_chat {
  margin-top: $px-40;
}

.call-view-more a {
  display: inline-block;
  background-color: #650681;
  color: #fff;
  padding: $px-10 $px-25;
  border-radius: $px-32;
  font-size: $px-13;
  font-weight: bold;
}

.missed-call-widget .card-body hr,
.other-call-widget .card-body hr {
  border-color: rgba(77, 77, 77, 0.1);
}

.missed-call-widget .card-body h6,
.other-call-widget .card-body h6 {
  font-size: $px-14;
}

.missed-call-widget .card-body i.missed-col,
.other-call-widget .card-body i.incoming-col {
  width: $px-17;
  display: inline-block;
}

.missed-call-widget .card-body i.missed-col img,
.other-call-widget .card-body i.incoming-col img {
  max-width: 100%;
  height: auto;
}

/*-----------------
  7. Add Group
-----------------------*/
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto $px-30;
  width: $px-120;
  border-radius: 50%;
  cursor: pointer;
}

.upload-icon {
  color: #ffa977;
  background-color: #f2f2f2;
  font-size: $px-40;
  width: $px-120;
  height: $px-120;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.chat_input {
  width: 100%;
  min-height: $px-40;
  padding: $px-6 $px-8 $px-6 $px-14;
  background-color: #fff;
  padding-left: $px-50 !important;
  border: $px-1 solid #c9cdd3;
  border-radius: $px-6 !important;
}

.search_filters {
  margin: 0px $px-15 0px;
}

.filter-item {
  margin-bottom: $px-12;

  .k-picker-md {
    .k-input-inner {
      .k-input-value-text {
        color: #b3b8c2;
      }
    }
  }
  .k-picker-solid {
    background-color: #ffffff !important;
    border: $px-1 solid #c9cdd3;
    background-image: none !important;
    border-radius: $px-6;
  }
  .k-input-button kendo-icon-wrapper kendo-icon::before {
    color: rgba(157, 164, 176, 1) !important;
  }
}

.btn-filter {
  position: absolute;
  right: $px-6;
  margin-top: -$px-36;
  padding: $px-6;
  width: $px-32;
  height: $px-32;
  text-align: center;
  line-height: $px-18;

  &:hover {
    border-radius: $px-9999;
    background-color: #d9f2ff;
  }

  &.active {
    border-radius: $px-9999;
    background-color: #d9f2ff;
  }
}

.btn-filter:hover svg-icon svg g path,
.btn-filter.active svg-icon svg g path {
  fill: rgba(65, 126, 184, 1) !important;
}

span.msg-badge {
  margin-left: $px-2;
  padding: $px-2 $px-6;
  border-radius: $px-10;
  background-color: #ffd600;
  color: #004876;
  font-size: $px-11;
  font-weight: 500;
  line-height: $px-20;
  letter-spacing: -$px-0-2;
}

.search_chat {
  position: relative;
  margin: 0px $px-15 $px-12;
  padding-top: $px-16 !important;
}

a.btn.btn-outline-light {
  background: 0 0;
}

.form-control-feedback {
  z-index: 0 !important;
  display: block;
  text-align: center;
  pointer-events: none;
  position: absolute;
  left: $px-13;
  top: $px-36;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  border-right: solid $px-1 #dee1e5;
  padding-right: $px-10;
}

.chat_cal {
  color: #680a83;
}

.members-call .list-inline {
  margin: 0;
}

.members-call .list-inline-item {
  margin: 0 !important;
  border: 0;
  width: $px-35;
  height: $px-35;
  margin-right: $px-7 !important;
}

.members-call .list-inline-item:last-child {
  margin-right: 0 !important;
}

.members-call .list-inline-item .btn {
  color: #595959;
  padding: $px-10;
  font-size: $px-12;
  border: $px-1 solid #595959;
  display: flex;
  display: -webkit-flex;
  width: $px-35;
  height: $px-35;
}

/*-----------------
  8. Group Icon
-----------------------*/
.group {
  background-color: #fff;
}

.phone_icon {
  -webkit-transform: rotate(140deg) !important;
}

.group_img_li {
  padding: 0.3375rem;
  list-style: none;
}

.avator {
  position: relative;
}

.avator_img {
  width: $px-50;
  height: $px-50;
  object-fit: cover;
}

/*-----------------
  10. File Attach
-----------------------*/
.upload-drop-zone {
  height: $px-150;
  border-width: $px-2;
  margin-bottom: $px-20;
  color: #888;
  border-style: dashed;
  border-color: #adb7be;
  line-height: $px-150;
  text-align: center;
  background-color: #fff;
}

.upload-list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.upload-list .file-list:first-child {
  border-top: none;
}

.upload-list .file-list {
  background-color: #fff;
  border-top: $px-1 solid #d8e2e7;
  padding: $px-10;
}

.upload-list .upload-wrap {
  position: relative;
  padding: 0 $px-20 0 0;
  margin: 0 0 $px-5;
}

.upload-list .file-name {
  padding-right: $px-15;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.upload-list .file-name i {
  color: #fda75c;
  margin: 0 $px-5 0 0;
  vertical-align: middle;
}

.upload-list .file-size {
  color: #888;
}

.upload-list .file-name,
.upload-list .file-size {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.upload-list .upload-process {
  font-size: $px-10;
  color: #888;
}

.upload-list .file-close:hover {
  color: #f62d51;
}

.upload-list .file-close {
  border: none;
  background: none;
  color: #ccc;
  position: absolute;
  right: 0;
  top: $px-2;
}

/*-----------------
  11. Chat Icon
-----------------------*/
.chat-right .chat-action-btns {
  float: right;
}

.chats .chat-action-btns {
  display: none;
}

.chat-action-btns ul {
  list-style: none;
  padding: 0;
  margin: $px-15 $px-15 0 $px-15;
}

.chat-action-btns ul > li {
  display: inline-block;
  margin-left: $px-5;
  font-size: $px-18;
}

.chats .chat-action-btns a {
  color: #8c8c8c;
}

.chats {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: $px-10;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

input,
button,
a {
  transition: all 0.4s ease;
}

a.del-msg {
  font-size: $px-15;
}

a.edit-msg {
  font-size: $px-15;
}

a.share-msg {
  font-size: $px-15;
}

.chats.chats-right {
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}

i.fas.fa-arrow-left {
  font-size: $px-18;
}

.avatar-group.mt-3 {
  margin-left: $px-80;
}

.chatlive {
  filter: blur($px-32);
}

i.fas.fa-search.ellipse_header {
  color: #ffa977;
}

i.fas.fa-ellipsis-h.ellipse_header {
  color: #ffa977;
}

.arrow {
  color: #fff;
}

.send-btn {
  width: $px-30;
  height: $px-30;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  color: #fff !important;
  background-color: #ffa977;
}

.chat_status {
  display: flex;
  display: -webkit-flex;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0 !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: auto;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.carousel-control-next {
  right: -$px-30;
}

.carousel-control-prev {
  left: -$px-30;
}

.text_carousel {
  line-height: 4;
}

.send-btn1 {
  width: $px-52;
  height: $px-52;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background-color: #ffa977;
}

/*-----------------
  12. Audiocall modal
-----------------------*/
.call-box .call-wrapper {
  height: auto;
  text-align: center;
}

.call-wrapper {
  height: calc(90vh - $px-145);
  position: relative;
  .call-items {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
}

.call-box .call-wrapper .call-user {
  margin-bottom: $px-30;
}

.call-box .call-wrapper .call-avatar {
  margin-bottom: $px-30;
  cursor: pointer;
}

.call-box .call-avatar {
  border-radius: 100%;
  position: relative;
  border: $px-7 solid #fafafa;
  height: 6.39rem;
  width: 6.39rem;
}

.call-box .call-wrapper .call-user h4 {
  font-weight: 500;
  font-size: 1.35rem;
}

.call-box .call-wrapper .call-user span {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: $px-19;
  color: #650681;
}

.call-modal .modal-content {
  border: 0;
  border-radius: $px-10;
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
  margin: 0 $px-10;
}

.call-box .call-wrapper .call-items .call-item.call-start,
.call-box .call-wrapper .call-items .call-item.call-end {
  background-color: #55ce63;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
  width: $px-60;
  height: $px-60;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  cursor: pointer;
  font-size: $px-18;
}

.call-box .call-wrapper .call-items .call-item.call-end {
  background-color: #f06060;
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
  margin: 0 $px-10;
}

.voice_content {
  border: 0;
  border-radius: $px-10 !important;
  background-image: linear-gradient(45deg, #660881, transparent);
  background-image: -webkit-linear-gradient(45deg, #660881, transparent);
  background-image: -ms-linear-gradient(45deg, #660881, transparent);
}

.voice_body {
  padding: $px-50 0;
}

.voice_chat_phone {
  font-size: $px-18;
}

/*-----------------
  13. Voice Call
-----------------------*/

.call-view {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  width: 75%;
}

.last-seen {
  color: #888;
  display: block;
  font-size: $px-12;
}

.voice-call-avatar .call-avatar {
  margin: $px-15;
  width: $px-150;
  height: $px-150;
  border-radius: 100%;
  border: $px-1 solid rgba(0, 0, 0, 0.1);
  padding: $px-3;
  background-color: #fff;
}

.voice-call-avatar .username {
  font-size: $px-20;
  font-weight: 500;
}

.voice-call-avatar .call-timing-count {
  padding: $px-5;
}

.call-icons .call-items {
  border-radius: $px-5;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: $px-5;
}

.call-icons .call-items .call-item a {
  color: #fff;
  border: $px-1 solid #ddd;
  width: $px-50;
  height: $px-50;
  line-height: $px-50;
  border-radius: $px-50;
  display: inline-block;
  font-size: $px-20;
  background-color: #9c27b0;
}

.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}

.call-icons .call-items .call-item a i {
  width: $px-18;
  height: $px-18;
}

.end-call {
  position: absolute;
  top: $px-7;
  right: 0;
}

.end-call a {
  background-color: #f06060;
  border-radius: $px-50;
  color: #fff;
  display: inline-block;
  line-height: $px-10;
  padding: $px-8 $px-25;
  text-transform: uppercase;
}

.voice_header {
  width: 2.25rem !important;
  height: 2.25rem !important;
}

/*-----------------
  14. Video Call
-----------------------*/
.container-fluid {
  padding-left: $px-30;
  padding-right: $px-30;
}

.user-img {
  display: inline-block;
  position: relative;
}

.bottom-message-col .form_status {
  border-radius: $px-24;
  background-color: #d9dadd;
  height: $px-48;
  padding: $px-10 $px-25;
  color: #000;
  width: 100%;
  font-size: $px-14;
}

.bottom-message-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.status_content {
  z-index: 0 !important;
  position: fixed;
  top: 40%;
  left: 60%;
}

.status_content_h3 {
  z-index: 0 !important;
  position: fixed;
  top: 65%;
  left: 45%;
}

.call-icons {
  text-align: center;
  position: relative;
}

.call-duration {
  display: inline-block;
  font-size: $px-30;
  margin-top: $px-4;
  position: absolute;
  left: 0;
}

.submit-section {
  text-align: center;
  margin-top: $px-40;
}

.submit-btn {
  color: #fff;
  text-transform: uppercase;
  border-radius: $px-50;
  font-size: $px-18;
  font-weight: 600;
  min-width: $px-200;
  padding: $px-10 $px-20;
}

/*-----------------
  15. Carousel
-----------------------*/

.carousel {
  margin: 0 auto;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  opacity: 1;
  background-image: none;
  width: auto;
  height: auto;
  font-size: $px-28;
}

.status_update {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0 !important;
}

.status_update + div {
  -webkit-filter: blur($px-32);
  -moz-filter: blur($px-32);
  -ms-filter: blur($px-32);
  -o-filter: blur($px-32);
  filter: blur($px-32);
}

/*-----------------
  16. Custom Scroll
-----------------------*/

.mCSB_scrollTools {
  opacity: 1 !important;
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: rgb(206, 212, 218);
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #680a83;
}

.right-sidebar .mCSB_inside > .mCSB_container {
  margin-right: 0;
}

/*-----------------
  17. Login
-----------------------*/
.account-page {
  background-color: #f7f7f7;
}

.account-page .content {
  padding: $px-50 0;
  width: 100%;
}

.login-right {
  background-color: #fff;
  border: decrease-by-10-percent(0.9px) solid #f0f0f0;
  border-radius: 5.4px;
  padding: 0px 22.5px 22.5px 22.5px;
  box-shadow: 0 7.2px 21.6px rgba(0, 0, 0, 0.1);
}

.login-header {
  /* background-image: url('../img/top-curve-bg.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: $px-126;
  padding: 25.2px $px-18 $px-18;
  text-align: center;
  margin: 0 -22.5px;
  width: auto;
  border-radius: 5.4px 5.4px 0 0;
}

.login-header p {
  margin-bottom: 0;
}

.login-header h3 {
  font-size: 19.8px;
  margin-bottom: 2.7px;
  font-weight: 500;
  text-align: center;
}

.login-header h3 span {
  font-size: 16.2px;
}

.login-header h3 a {
  color: #0de0fe;
  float: right;
  font-size: 13.5px;
  margin-top: 1.8px;
}

.login-right .dont-have {
  color: #3d3d3d;
  margin-top: $px-18;
  font-size: 12.6px;
}

.login-right .dont-have a {
  color: #680a83;
}

.login-btn {
  font-size: 16.2px;
  font-weight: 500;
}

.login-or {
  color: #555;
  margin-bottom: $px-18;
  margin-top: $px-18;
  padding-bottom: $px-9;
  padding-top: $px-9;
  position: relative;
}

.or-line {
  background-color: #d9d9d9;
  height: $px-1;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  margin-left: -$px-20;
  position: absolute;
  text-align: center;
  top: -$px-3;
  width: $px-42;
}

.forgot-link {
  display: inline-block;
  font-size: $px-14;
  font-weight: 400;
  text-align: right;
  width: 100%;
}

.btn-facebook {
  background-color: #3a559f;
  color: #fff;
  font-size: $px-14;
  padding: $px-10 $px-12;
  width: 100%;
  display: block;
}

.btn-google {
  background-color: #dd4b39;
  color: #fff;
  font-size: $px-14;
  padding: $px-10 $px-12;
  width: 100%;
  display: block;
}

.social-login .btn:hover,
.social-login .btn:focus {
  color: #fff;
}

.forgot-link a {
  color: #680a83;
}

.ng-scrollbar-thumb {
  background-color: #680a83 !important;
}

/*-----------------
  18. Responsive
-----------------------*/

@media screen and (max-width: $px-1200) {
  .profile_wrap {
    width: $px-320 !important;
  }

  .status_content_h3 {
    font-size: $px-24;
  }

  .sidebar-group.mobile-open {
    opacity: 1;
    right: 0;
    visibility: visible;
  }

  .right-sidebar {
    margin-right: -$px-320;
  }
}

@media screen and (max-width: decrease-by-10-percent(991.98px)) {
  body {
    font-size: $px-13;
  }

  .main-wrapper .content .sidebar-group {
    width: 100%;
  }

  .chat,
  .right-sidebar {
    width: 100%;
  }
  .main-wrapper .content {
    flex-direction: column !important;
    z-index: 0 !important;
  }

  .main-wrapper .content .sidebar-group .sidebar {
    width: 100%;
  }

  .right-sidebar .sidebar-body .pl-4,
  .right-sidebar .sidebar-body .pr-4 {
    padding: 0 !important;
  }

  .header_button {
    bottom: -$px-18;
  }

  .nav.nav-tabs.chat-tabs .nav-item .nav-link,
  .nav.nav-tabs.chat-tabs .nav-item .nav-link.active {
    font-size: $px-14;
    padding-right: $px-8;
  }

  .chat .chat-header {
    padding: $px-15 $px-15 $px-10;
  }

  .chat .chat-body {
    padding: $px-20 $px-15 $px-58;
  }

  .chat .chat-header .chat-options {
    margin-top: $px-15;
    margin-left: $px-35;
  }

  .status_update {
    display: none;
  }

  .chat .chat-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: $px-10 $px-15;
    height: $px-58;
    top: inherit;
    padding-left: $px-80 !important;
  }

  .contact_profile_icon {
    top: $px-44;
    left: $px-50;
  }

  .right-sidebar {
    width: 100%;
    padding-right: 0;
  }

  body .sidebar-group .sidebar .list-group-item .users-list-body h5 {
    font-size: $px-16;
  }

  .chat .chat-header .user-details h5 {
    font-size: $px-19;
  }

  .account-page .container-fluid {
    height: 100%;
  }

  .account-page .container-fluid > div {
    align-items: center;
    -webkit-align-items: center;
    height: 100%;
  }

  .left-sidebar.hide-left-sidebar {
    margin-left: -$px-991;
  }

  .chat.show-chatbar {
    display: block;
    margin-left: 0;
  }

  .chat.hide-chatbar {
    margin-left: -$px-991;
    display: none;
  }

  .right-sidebar.hide-right-sidebar {
    margin-right: -$px-991;
  }

  .contact-close_call {
    margin-right: $px-15 !important;
  }
}

@media screen and (max-width: decrease-by-10-percent(767.98px)) {
  .main-wrapper {
    overflow: auto;
  }

  .login-right {
    margin-bottom: $px-40;
  }

  .chat .chat-header {
    display: block;
  }

  .chat .mob-auto {
    max-width: 25%;
  }

  .avatar-group {
    padding-top: $px-20;
    padding-left: $px-35;
  }

  .status-title {
    margin-left: 0 !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    font-size: $px-25;
  }

  .status-modal .modal-dialog {
    padding: $px-20 $px-10;
  }

  .carousel-control-prev {
    left: -$px-25;
  }

  .carousel-control-next {
    right: -$px-25;
  }

  .send-btn_status {
    width: $px-48;
    height: $px-48;
    margin-left: $px-8;
    font-size: $px-20;
  }

  .status-modal .modal-dialog .modal-content .inner-popup {
    padding: 0 $px-10;
  }

  .missed-call-widget .card-body,
  .other-call-widget .card-body {
    padding: $px-30 $px-8;
  }

  .avatar-group.group-col {
    padding-top: 0;
    padding-left: $px-15;
  }

  .missed-call-widget .card-body h6,
  .other-call-widget .card-body h6 {
    font-size: $px-13;
  }
}

.location-msg {
  padding: $px-8 $px-12;
  align-items: center;
  border-radius: $px-18;
  background-color: #fffffff2;
  color: #417eb8;
  font-size: $px-14;
  cursor: pointer;
}

.location-msg:hover {
  font-weight: 400;
  text-decoration-line: underline;
}

.location-msg svg-icon {
  padding-right: $px-8;
}

.img-msg {
  width: $px-300;
  height: $px-200;
}

.img-msg img {
  width: 100% !important;
  border-radius: $px-13;
}

.modal-content .modal-header .btn-close:focus {
  box-shadow: none !important;
}

.modal-content .modal-header .btn-close {
  color: rgba(152, 162, 179, 1) !important;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2398a2b3'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat !important;
  background-size: 10.5px !important;
}

.w-100 {
  width: 100% !important;
}

.btn-warning-modal {
  border-radius: $px-6;
  border: $px-1 solid #f59e0b;
  background-color: #f59e0b;
  display: inline !important;
  box-shadow: 0px $px-1 $px-2 0px rgba(45, 54, 67, 0.05);
  padding: $px-6 $px-12;
  height: $px-36;

  &:hover {
    background-color: #ff8600 !important;
    border: $px-1 solid #ff8600 !important;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }

  &:active {
    background-color: #ff8600 !important;
    border: $px-1 solid #ff8600 !important;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }

  &:focus {
    background-color: #ff8600 !important;
    border: $px-1 solid #ff8600 !important;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }
}

.warning-modal-icon {
  width: $px-40 !important;
  height: $px-40 !important;
  border-radius: $px-6;
  background-color: #fff7ec;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: $px-20;
}

.btn-danger-modal {
  border-radius: $px-6;
  border: $px-1 solid #e46962;
  background-color: #e46962;
  display: inline !important;
  box-shadow: 0px $px-1 $px-2 0px rgba(45, 54, 67, 0.05);
  padding: $px-6 $px-12;
  height: $px-36;

  &:hover {
    background-color: #dc362e;
    border: $px-1 solid #dc362e;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }

  &:active {
    background-color: #dc362e;
    border: $px-1 solid #dc362e;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }

  &:focus {
    background-color: #dc362e;
    border: $px-1 solid #dc362e;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }
}

.btn-outline-modal {
  border-radius: $px-6;
  border: $px-1 solid #e2e8f0 !important;
  background-color: #fff;
  display: inline !important;
  margin-right: $px-8 !important;
  box-shadow: 0px $px-1 $px-2 0px rgba(45, 54, 67, 0.05);
  height: $px-36;

  &:hover {
    background-color: #f1f5f9;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }
}
.btn-outline-add-msg-modal {
  display: flex !important;
  height: $px-32;
  padding: $px-6 $px-12 !important;
  justify-content: center;
  align-items: center;
  gap: $px-10;
  border-radius: $px-6;
  border: solid $px-1 #cbd5e1 !important;
  background-color: #fff;
  box-shadow: 0px $px-1 $px-2 0px rgba(45, 54, 67, 0.05);
  color: #64748b;
  font-size: $px-14;
  &:hover {
    background-color: #f1f5f9;
    box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
  }
}
.add-modal-action {
  gap: $px-16 !important;
}
.btn-add-msg {
  display: flex !important;
  height: $px-32;
  padding: $px-6 $px-12 !important;
  justify-content: center;
  align-items: center;
  gap: $px-10;
  border-radius: $px-6;
  border: $px-1 solid #185b8d;
  background-color: #185b8d;
  box-shadow: 0px $px-1 $px-2 0px rgba(45, 54, 67, 0.05);
  color: #fff;
  font-size: $px-14;
  &:hover {
    background-color: #154876;
    box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
    color: #fff;
  }
  &:active {
    background-color: #154876;
    box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
    color: #fff;
  }
  &:focus {
    background-color: #154876;
    box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
    color: #fff;
  }
  &:disabled {
    background-color: #b1dcff !important;
    border: $px-1 solid #b1dcff;
    color: #ffffff !important;
  }
}

.msg-modal-title {
  color: #000;
  text-align: center;
  font-size: $px-18;
  font-style: normal;
  font-weight: 600;
  line-height: $px-30;
  letter-spacing: -$px-0-3;
  margin-bottom: $px-8;
}

.msg-modal-text {
  color: #64748b;
  text-align: center;
  font-size: $px-14;
  font-style: normal;
  font-weight: 400;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  margin-bottom: 0px;
}

.newMessage-modal {
  .modal-dialog {
    margin-top: $px-64;
    .modal-content {
      border-radius: 0.45rem;
      .modal-body {
        padding: $px-24;
        .modal-title {
          .msg-modal-title {
            text-align: left !important;
            font-size: $px-16;
            margin-bottom: $px-24;
          }
        }
      }
    }
  }
}
.addLocation-modal {
  .modal-dialog {
    max-width: 848px;
    width: 100%;
    margin-top: $px-64;
    .modal-content {
      border-radius: $px-20;
      .modal-body {
        .modal-title {
          .msg-modal-title {
            text-align: left !important;
            font-size: $px-16;
            margin-bottom: $px-12;
          }
        }
      }
    }
  }
}
.new-msg-footer {
  margin-top: $px-20;
}
.location-container-input {
  margin: $px-20 0 !important;
  gap: $px-12;
}
.location-container-input div label {
  margin-bottom: 0px !important;
  line-height: $px-32;
}
.new-msg-form-group label {
  color: #606977 !important;
  font-size: $px-14 !important;
  font-style: normal;
  font-weight: 500;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  margin-bottom: $px-6;
}
.new-msg-form-group kendo-dropdownlist {
  border-radius: $px-6;

  border: $px-1 solid #c9cdd3;
  background-color: #fff;
  height: $px-36;
}
.new-msg-form-group kendo-dropdownlist:hover {
  border-radius: $px-6;

  border: $px-1 solid #c9cdd3;
  background-color: #fff;
  height: $px-36;
}
.new-msg-form-group kendo-dropdownlist .k-input-inner .k-input-value-text {
  color: #004876;
  font-size: $px-14;
  font-style: normal;
  font-weight: 500;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
}
.new-msg-form-group
  kendo-dropdownlist.dll-notselected
  .k-input-inner
  .k-input-value-text {
  color: #b3b8c2;
}

.new-msg-form-group kendo-dropdownlist.k-disabled {
  border: $px-1 solid #c9cdd3 !important;
  background-color: #f8fafc !important;
}
.msg-text-area-form-control:disabled {
  border: $px-1 solid #c9cdd3 !important;
  background-color: #f8fafc !important;
}
.msg-text-area-form-control {
  height: $px-100 !important;
  border: $px-1 solid #c9cdd3;
  background-color: #fff;
  color: #004876 !important;
  font-size: $px-14;
  font-style: normal;
  font-weight: 500;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
}

.chat .chat-header .chat-options ul > li > a {
  font-size: $px-16;
  color: #292f4c;
  opacity: 0.5;
  border-radius: 50% !important;
  cursor: pointer;
  width: $px-24;
  height: $px-24;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat .chat-header .chat-options ul > li > a:hover {
  background-color: #d9f2ff;
}
.chat .chat-header .chat-options ul > li > a:hover svg-icon svg path {
  stroke: rgba(65, 126, 184, 1) !important;
}

/*
max-width: 848px;
width: 100%; */

.no-conversation-container {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.No-Conversation-text {
  color: #64748b;
  font-size: $px-16;
  font-style: normal;
  font-weight: 400;
  line-height: $px-28;
  letter-spacing: -$px-0-3;
  margin-top: $px-12;
}
.router-lg-view {
  .chat-wrapper {
    border-top-left-radius: 0px !important;
    border-color: #e2e8f0;
    background-image: none;
  }
}
.btn-modal-close:focus {
  box-shadow: none !important;
}

.modal-action {
  gap: $px-8;
}
.sidebar-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $px-6 #c9cdd3;
  border-radius: $px-10;
  background-color: #c9cdd3;
}

.sidebar-body::-webkit-scrollbar {
  width: $px-6;
  background-color: #417eb8;
}

.sidebar-body::-webkit-scrollbar-thumb {
  border-radius: $px-10;
  -webkit-box-shadow: inset 0 0 $px-6 #417eb8;
  background-color: #555;
}

.chat-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $px-6 #c9cdd3;
  border-radius: $px-10;
  background-color: #c9cdd3;
}

.chat-body::-webkit-scrollbar {
  width: $px-6;
  background-color: #417eb8;
}

.chat-body::-webkit-scrollbar-thumb {
  border-radius: $px-10;
  -webkit-box-shadow: inset 0 0 $px-6 #417eb8;
  background-color: #555;
}

.locationChat {
  padding: $px-8 $px-12 $px-8 $px-12;
  display: flex;
  justify-content: flex-start;
  gap: $px-8;
  background-color: #fffffff2;
  border-radius: $px-18;
}

.locationChatMessage {
  color: #417eb8;
  font-size: $px-14;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.chat-Image {
  width: $px-300;
  height: $px-200;
  border-radius: $px-13;
}

.chat-Image:hover {
  cursor: pointer;
}

.chatDoc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $px-8;
}
