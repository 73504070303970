@import "/src/assets/styles/functions";
@import "/src/assets/styles/variables";
/********************* Delete dialog ********************************/
.confirmationDialog.DeleteDialog {
  .k-window {
    box-shadow:
      0px $px-20 $px-24 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04);
    border-radius: $px-13;
    width: $px-400 !important;
    background-color: #fff;

    .k-window-titlebar {
      background-color: #fff;
      border: 0px;
      border-radius: $px-13;
      padding: $px-24 $px-24 0 $px-24;

      .iconWraber {
        background-color: #fceeee;
        width: $px-40;
        height: $px-40;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $px-6;

        .deleteIcon {
          color: #dc362e;
        }
      }

      .k-window-titlebar-actions {
        .k-button-icon {
          color: #98a2b3;
        }
      }
    }

    .destructive {
      background-color: #e46962;
      border: $px-1 solid #e46962;
      color: #ffffff;
      border-radius: $px-6;
      width: $px-124;

      &:hover {
        background-color: #dc362e;
        border: $px-1 solid #dc362e;
        box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
      }

      &:active {
        background-color: #dc362e;
        border: $px-1 solid #dc362e;
        box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
        color: #ffffff;
      }

      &:disabled {
        background-color: #f9dedc;
        border: $px-1 solid #f9dedc;
        color: #ec928e;
      }
    }

    .k-window-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: $px-12 $px-24 $px-24 $px-24;

      .confirmationText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: $px-20;
        line-height: $px-32;
        letter-spacing: -$px-0-3;
        color: #000000;
      }

      .hintText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: $px-16;
        line-height: $px-28;
        letter-spacing: -$px-0-3;
        color: #64748b;
        margin-top: $px-8;
      }

      .optional {
        border-radius: $px-6;
        width: $px-124;
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: $px-12;
        margin-top: $px-32;
      }
    }
  }
}

/********************* Save dialog ********************************/
.confirmationDialog.SaveDialog {
  .k-window {
    box-shadow:
      0px $px-20 $px-24 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04);
    border-radius: $px-13;
    width: $px-400 !important;
    background-color: #fff;

    .k-window-titlebar {
      background-color: #fff;
      border: 0px;
      border-radius: $px-13;
      padding: $px-24 $px-24 0 $px-24;

      .iconWraber {
        background-color: #d9f2ff;
        width: $px-40;
        height: $px-40;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $px-6;

        .deleteIcon {
          color: #185b8d;
        }
      }

      .k-window-titlebar-actions {
        .k-button-icon {
          color: #98a2b3;
        }
      }
    }

    .k-window-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: $px-12 $px-24 $px-24 $px-24;

      .confirmationText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: $px-20;
        line-height: $px-32;
        letter-spacing: -$px-0-3;
        color: #000000;
      }

      .hintText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: $px-16;
        line-height: $px-28;
        letter-spacing: -$px-0-3;
        color: #64748b;
        margin-top: $px-8;
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: $px-12;
        margin-top: $px-32;
      }

      .optional {
        border-radius: $px-6;
        width: $px-124;
      }

      .destructive {
        background-color: #185b8d;
        border: $px-1 solid #185b8d;
        color: #fff;
        border-radius: $px-6;
        width: $px-124;

        &:hover {
          background-color: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
        }

        &:active {
          background-color: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
          color: #fff;
        }

        &:disabled {
          background-color: #b1dcff;
          border: $px-1 solid #b1dcff;
        }
      }
    }
  }
}

/********************* Add dialog ********************************/
.confirmationDialog.AddDialog {
  .k-window {
    box-shadow:
      0px $px-20 $px-24 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04);
    border-radius: $px-13;
    width: $px-400 !important;
    background-color: #fff;

    .k-window-titlebar {
      background-color: #fff;
      border: 0px;
      border-radius: $px-13;
      padding: $px-24 $px-24 0 $px-24;

      .iconWraber {
        background-color: #d9f2ff;
        width: $px-40;
        height: $px-40;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $px-6;

        .deleteIcon {
          color: #185b8d;
        }
      }

      .k-window-titlebar-actions {
        .k-button-icon {
          color: #98a2b3;
        }
      }
    }

    .k-window-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: $px-12 $px-24 $px-24 $px-24;

      .confirmationText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: $px-20;
        line-height: $px-32;
        letter-spacing: -$px-0-3;
        color: #000000;
      }

      .hintText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: $px-16;
        line-height: $px-28;
        letter-spacing: -$px-0-3;
        color: #64748b;
        margin-top: $px-8;
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: $px-12;
        margin-top: $px-32;
      }

      .optional {
        border-radius: $px-6;
        width: $px-124;
      }

      .destructive {
        background-color: #185b8d;
        border: $px-1 solid #185b8d;
        color: #fff;
        border-radius: $px-6;
        width: $px-124;

        &:hover {
          background-color: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
        }

        &:active {
          background-color: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
          color: #fff;
        }

        &:disabled {
          background-color: #b1dcff;
          border: $px-1 solid #b1dcff;
        }
      }
    }
  }
}

/********************* Warning dialog ********************************/
.confirmationDialog.WarningDialog {
  .k-window {
    box-shadow:
      0px $px-20 $px-24 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04);
    border-radius: $px-13;
    width: $px-400 !important;
    background-color: #fff;

    .k-window-titlebar {
      background-color: #fff;
      border: 0px;
      border-radius: $px-13;
      padding: $px-24 $px-24 0 $px-24;

      .iconWraber {
        background-color: #fff7ec;
        width: $px-40;
        height: $px-40;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $px-6;

        .deleteIcon {
          color: #fff7ec;
        }
      }

      .k-window-titlebar-actions {
        .k-button-icon {
          color: #98a2b3;
        }
      }
    }

    .k-window-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: $px-12 $px-24 $px-24 $px-24;

      .confirmationText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: $px-20;
        line-height: $px-32;
        letter-spacing: -$px-0-3;
        color: #000000;
      }

      .hintText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: $px-16;
        line-height: $px-28;
        letter-spacing: -$px-0-3;
        color: #64748b;
        margin-top: $px-8;
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: $px-12;
        margin-top: $px-32;
      }

      .optional {
        border-radius: $px-6;
        width: $px-124;
      }

      .destructive {
        background-color: #d97706;
        border: $px-1 solid #d97706;
        color: #ffffff;
        border-radius: $px-6;
        width: $px-124;

        &:hover {
          background-color: #d97706;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
        }

        &:active {
          background-color: #d97706;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
          color: #fff;
        }

        &:disabled {
          background-color: #b1dcff;
          border: $px-1 solid #b1dcff;
        }
      }
    }
  }
}

/********************* Success dialog ********************************/
.confirmationDialog.SuccessDialog {
  .k-window {
    box-shadow:
      0px $px-20 $px-24 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04);
    border-radius: $px-13;
    width: $px-400 !important;
    background-color: #fff;

    .k-window-titlebar {
      background-color: #fff;
      border: 0px;
      border-radius: $px-13;
      padding: $px-24 $px-24 0 $px-24;

      .iconWraber {
        background-color: #d7ffeb;
        width: $px-40;
        height: $px-40;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $px-6;

        .deleteIcon {
          color: #d7ffeb;
        }
      }

      .k-window-titlebar-actions {
        .k-button-icon {
          color: #98a2b3;
        }
      }
    }

    .k-window-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: $px-12 $px-24 $px-24 $px-24;

      .confirmationText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: $px-20;
        line-height: $px-32;
        letter-spacing: -$px-0-3;
        color: #000000;
      }

      .hintText {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: $px-16;
        line-height: $px-28;
        letter-spacing: -$px-0-3;
        color: #64748b;
        margin-top: $px-8;
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: $px-12;
        margin-top: $px-32;
      }

      .optional {
        border-radius: $px-6;
        width: $px-124;
      }

      .destructive {
        background-color: #185b8d;
        border: $px-1 solid #185b8d;
        color: #fff;
        border-radius: $px-6;
        width: $px-124;

        &:hover {
          background-color: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
        }

        &:active {
          background-color: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
          color: #fff;
        }

        &:disabled {
          background-color: #b1dcff;
          border: $px-1 solid #b1dcff;
        }
      }
    }
  }
}

/********************* about dialog ********************************/

.about-dialog {
  .k-window {
    box-shadow:
      0px $px-20 $px-24 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04);
    border-radius: $px-13;
    width: $px-336 !important;
    background-color: #fff;

    .k-window-titlebar {
      background-color: #fff;
      border: 0px;
      border-radius: $px-13;
      padding: $px-24 $px-24 0 $px-24;

      .k-window-titlebar-actions {
        .k-button-icon {
          color: #98a2b3;
          width: $px-24;
          height: $px-24;
        }
      }

      .k-window-title {
        padding: 0px;
      }
    }

    .icon-container {
      display: flex;
      justify-content: center;
      margin-bottom: $px-12;

      .icon-wrapper {
        background-color: #d7ffeb;
        width: $px-40;
        height: $px-40;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $px-6;

        .delete-icon {
          color: #dc362e;
        }
      }
    }

    .k-window-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0px $px-24 $px-24 $px-24;

      .confirmation-text {
        color: #000;
        text-align: center;
        font-size: $px-18;
        font-style: normal;
        font-weight: 600;
        line-height: $px-30;
        letter-spacing: -$px-0-3;
        margin-bottom: $px-12;
      }

      .company-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: $px-12;

        .company-title {
          color: #004876;
          font-size: $px-16;
          font-style: normal;
          font-weight: 600;
          line-height: $px-28;
          letter-spacing: -$px-0-3;
        }

        .companny-version {
          color: #94a3b8;
          font-size: $px-12;
          font-style: normal;
          font-weight: 400;
          line-height: $px-20;
          letter-spacing: -$px-0-2;
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: $px-12;

        .hint-text {
          color: #475569;
          text-align: center;
          font-size: $px-14;
          font-style: normal;
          font-weight: 400;
          line-height: $px-24;
          letter-spacing: -$px-0-2;
        }

        .support-link {
          color: #417eb8;
          font-size: $px-14;
          font-style: normal;
          font-weight: 400;
          line-height: $px-24;
          letter-spacing: -$px-0-2;
          text-decoration-line: underline;
        }
      }

      .rights-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .right-text {
          color: #94a3b8;
          font-size: $px-12;
          font-style: normal;
          font-weight: 400;
          line-height: $px-20;
          letter-spacing: -$px-0-2;
        }
      }

      .buttons-container {
        display: flex;
        justify-content: center;
        margin-top: $px-24;
      }
    }
  }
}
