@import "/src/assets/styles/functions";
@import "/src/assets/styles/variables";
/************* maps search input style *******************/
.input-wrapper-map {
  display: flex;
  align-items: center;
  position: relative;
  height: $px-36;
  top: $px-12 !important;

  .map-search-input {
    height: $px-36;

    padding-left: $px-52;

    border: $px-1 solid #c9cdd3;
    border-radius: $px-6;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: $px-14;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
    outline: 0px;

    &::placeholder {
      color: #b3b8c2;
    }

    &:focus {
      border-color: #154876;
      box-shadow:
        0px 0px 0px $px-1 #ffffff,
        0px 0px 0px $px-4 #b1dcff;
      color: #154876;
    }
  }

  .input-line {
    position: absolute;
    top: $px-12;
    left: $px-44;
    width: $px-1;
    height: $px-16;
    background-color: #dee1e5;
    z-index: 100;
  }

  .search-icon {
    position: absolute;
    top: $px-12;
    left: $px-18;
  }
}

.has-text {
  border-color: #154876;
  color: #154876;
}

.icon-wrapper {
  display: flex;
}

.input-label {
  position: absolute;
  top: $px-12;
  left: $px-44;
  font-size: $px-12;
  color: #777;
  transition: opacity 0.2s ease-in-out;
}

/********************************** dialog *****************************/


