@import "/src/assets/styles/functions";
@import "/src/assets/styles/variables";
.pageHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $px-24;
}

.PageHeadline {
  font-style: normal;
  font-weight: 600;
  font-size: $px-18;
  line-height: $px-28;
  display: flex;
  align-items: center;
  letter-spacing: -$px-0-3;
  color: #000000;
  margin-bottom: 0;
}

.k-picker-solid.k-invalid,
.k-picker-solid.ng-invalid.ng-touched,
.k-picker-solid.ng-invalid.ng-dirty {
  border-color: rgba(0, 0, 0, 0.08);
}

.form-control.is-invalid {
  background-color: #fceeee;
  border: $px-1 solid #dc362e !important;
  color: #dc362e;
}

.form-control.is-invalid:hover {
  border: $px-1 solid #dc362e;
}

.form-control.is-invalid:hover::placeholder {
  color: #dc362e;
}

.form-control.is-invalid::placeholder {
  color: #dc362e;
}

.form-control:hover::placeholder {
  color: #b3b8c2;
}

.form-control::placeholder {
  color: #b3b8c2;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("../images/icons/errorValidation.svg");
}

.form-control {
  height: $px-32;
  color: #334155;
}

/******************** icon input, input ****************/

.inputWrapper {
  &:has(.fieldInput:disabled) {
    background-color: #f8fafc;
    color: #c9cdd3;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: $px-32;
  border: $px-1 solid #c9cdd3;
  border-radius: $px-6;
  padding: $px-6 $px-14;
  background-color: #fff;

  .fieldInput {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: $px-6;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: $px-14;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
    outline: 0px;
    padding: 0px;
    resize: none;

    &::placeholder {
      color: #b3b8c2;
    }

    &:disabled {
      color: #c9cdd3 !important;
    }
  }

  .iconWrapper {
    font-weight: 600;
    font-size: $px-18;
    line-height: $px-28;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $px-32;
    height: 100%;
    margin-right: $px-8;

    .searchIcon {
      margin-right: $px-11;
      display: flex;
      align-items: center;
    }

    .inputLine {
      max-width: $px-1;
      height: $px-16;
      background-color: #dee1e5;
      flex-grow: 1;
    }
  }

  .textWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: $px-8;
    width: $px-70;

    span {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: $px-14;
      line-height: $px-24;
      color: #9da4b0;
      margin-right: $px-8;
    }

    .inputLine {
      max-width: $px-1;
      height: $px-16;
      background-color: #dee1e5;
      flex-grow: 1;
    }
  }

  .infoIcon {
    display: flex;
    align-items: center;
  }

  .searchIcon {
    stroke: #9da4b0;
    color: #9da4b0;
  }

  &.hasText {
    .fieldInput {
      color: #154876;
    }
  }

  &.isInvalid {
    .fieldInput {
      color: #dc362e !important;
      background-color: #fceeee;
    }

    background-color: #fceeee;
    border-color: #dc362e;

    &:focus-within {
      border-color: #dc362e;
      box-shadow: unset;
    }

    .searchIcon {
      stroke: #e46962;
      color: #e46962;
    }

    .inputLine {
      background-color: #f2b8b5 !important;
    }

    ::placeholder {
      color: #e46962;
    }
  }

  &:focus-within {
    border-color: #154876;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
  }

  &:focus-within .fieldInput {
    color: #154876;
  }

  .form-control {
    height: $px-36;
    color: #334155;
  }

  .form-control:disabled:focus,
  .form-control:disabled:active {
    background-color: #f8fafc;
    opacity: 1;
  }

  .form-control:disabled {
    background-color: #f8fafc;
    border: $px-1 solid #c9cdd3;
  }

  .form-control:disabled:hover {
    border: $px-1 solid #c9cdd3;
  }
}

.textareaclass {
  height: $px-70;
}

/************* icon Input customization *******************/

.input-group input.form-control.is-invalid {
  border-left: 0px;
}

.input-group-text {
  background-image: unset;
  background-color: unset;
  height: $px-36;
}

/***************** DropDowns Custom style *****************/

.dropDown_custom_field {
  width: 100% !important;
  display: flex;
  align-items: center;
  background-color: #fff;
  height: $px-32;
  border: $px-1 solid #c9cdd3 !important;
  border-radius: $px-6;
  padding: $px-6 $px-14;
  background-image: unset;
  color: #b3b8c2 !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $px-14;
  line-height: $px-24;
  letter-spacing: -$px-0-2;

  // .k-input-inner::placeholder {
  //   padding: 0px;
  //   color: #b3b8c2;

  // }

  .k-input-inner {
    padding: 0px;
    z-index: 0;
  }

  &.k-disabled {
    background-color: #f8fafc;
    color: #c9cdd3;
  }

  &:hover {
    background-color: #fff;
  }

  &:focus-within {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
  }

  &[aria-expanded="true"] {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
    color: #154876;
  }

  &.ng-dirty {
    color: #154876 !important;

    .k-i-caret-alt-down::before {
      color: #154876;
    }
  }

  &.is-invalid {
    border: $px-1 solid #dc362e !important;
    color: #dc362e !important;
    background-color: #fceeee;

    &[aria-expanded="true"] {
      border-color: #dc362e !important;
      box-shadow: unset;
    }

    .k-i-caret-alt-down::before {
      color: #dc362e;
    }
  }

  .k-i-caret-alt-down::before {
    color: #c9cdd3;
  }
}

.dropDown_custom_popup {
  .k-list-md .k-list-optionlabel.k-disabled.k-focus.k-selected {
    background-color: #2e6fa6;
    opacity: 1;
    box-shadow: unset;
    height: $px-36;
  }

  .k-list-item {
    margin: $px-5;
    height: fit-content !important;
    &.k-selected {
      background-color: #2e6fa6 !important;

      .k-list-item-text {
        color: #fff !important;
      }
    }
  }
}

/***************** button dropDown custom style ****************/

.grid_dropDown_custom_popup {
  padding: $px-8 0px;
  border-radius: $px-13;
  background-color: #fff;
  box-shadow: 0px 0px $px-10 $px-2 rgba(0, 0, 0, 0.1);

  kendo-button-list {
    .k-menu-group-md {
      .k-menu-item {
        .k-menu-link {
          height: $px-40;
          box-shadow: unset;

          &:hover {
            background-color: #f1f5f9;
          }

          &:active {
            background-color: #2e6fa6;

            svg-icon svg g g path {
              stroke: #fff;
            }

            span {
              color: #fff !important;
            }
          }

          span {
            font-size: $px-14;
            font-style: normal;
            font-weight: 400;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #334155;
          }

          svg-icon svg g g path {
            stroke: #334155;
          }
        }
      }
    }
  }
}

/**************** districts dropDown custom Style ***********/

.district_dropDown_custom_popup {
  .k-list-md .k-list-optionlabel.k-disabled.k-focus.k-selected {
    background-color: #2e6fa6;
    opacity: 1;
    box-shadow: unset;
    height: $px-36;
  }

  .k-list-item {
    margin: $px-5;
    height: fit-content !important;
  }

  .k-list-item.k-selected {
    background-color: #2e6fa6 !important;

    .k-list-item-text {
      color: #fff !important;
    }
  }
}

/***************** multi select custom style ****************/

.multiSelect_custom_field {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: $px-1 solid #c9cdd3 !important;
  border-radius: $px-6;
  padding: $px-6 $px-14;
  height: $px-32;
  color: #b3b8c2;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $px-14;
  line-height: $px-24;
  letter-spacing: -$px-0-2;

  .k-input-inner::placeholder {
    padding: 0px;
    color: #b3b8c2;
  }

  kendo-taglist {
    kendo-searchbar {
      .k-input-inner {
        padding: 0px;
      }
    }

    div {
      border-color: rgba(0, 0, 0, 0.08);
      color: #fff;
      background-color: #74a5db;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: $px-14;
      letter-spacing: -$px-0-2;

      &:hover {
        background-color: #74a5db;
      }

      .k-chip-content {
        margin-right: $px-6;
      }
    }
  }
  &:hover {
    background-color: #fff;
  }

  &.k-disabled {
    background-color: #f8fafc;
    color: #c9cdd3;
  }

  &:focus-within {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
  }

  &[aria-expanded="true"] {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
    color: #154876;
  }

  &.is-invalid {
    border: $px-1 solid #dc362e !important;
    color: #dc362e !important;
    background-color: #fceeee;

    &[aria-expanded="true"] {
      border-color: #dc362e !important;
      box-shadow: unset;
    }

    .k-i-caret-alt-down::before {
      color: #dc362e;
    }
  }

  .k-i-caret-alt-down::before {
    color: #c9cdd3;
  }
}

.multiSelect_custom_popup {
  margin-top: $px-2;

  .k-list-md .k-list-optionlabel.k-disabled.k-focus.k-selected {
    background-color: #2e6fa6;
    opacity: 1;
    box-shadow: unset;
    height: $px-36;
  }

  .k-list-item {
    margin: $px-5;
    height: fit-content !important;
  }

  .k-list-item.k-selected {
    background-color: #2e6fa6 !important;

    .k-list-item-text {
      color: #fff !important;
    }
  }
}

.multiSelectTree_custom_popup {
  .k-treeview-md {
    .k-treeview-lines {
      .k-treeview-item {
        div {
          height: $px-36;

          kendo-checkbox {
            input {
              margin-top: -$px-4;
            }
          }

          .k-treeview-leaf {
            width: 100%;
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #475569;

            &:hover {
              background-color: #f1f5f9;
            }

            &:focus {
              box-shadow: inset 0 0 0 $px-1 #2e6fa6 !important;
            }
          }

          .k-focus {
            box-shadow: inset 0 0 0 $px-1 #2e6fa6 !important;
          }
          .k-treeview-toggle {
            kendo-icon-wrapper {
              kendo-icon {
                color: #727c8d !important;

                &::before {
                  color: #727c8d !important;
                }
              }
            }
          }
        }

        .k-treeview-group {
          .k-treeview-item {
            display: flex;
            align-items: center;
            height: $px-36;

            div {
              width: 100%;
            }

            kendo-checkbox {
              input {
                margin-top: -$px-4;
              }
            }

            .k-treeview-leaf {
              width: 100%;
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: $px-14;
              line-height: $px-24;
              letter-spacing: -$px-0-2;
              color: #475569;

              &:hover {
                background-color: #f1f5f9;
              }

              &:focus {
                box-shadow: inset 0 0 0 $px-1 #2e6fa6 !important;
              }
            }

            .k-focus {
              box-shadow: inset 0 0 0 $px-1 #2e6fa6 !important;
            }
          }
        }
      }
    }
  }
}

/***************** datePicker Custom style *****************/

.datepicker-custom-field {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: $px-32;
  border: $px-1 solid #c9cdd3 !important;
  border-radius: $px-6;
  padding: $px-6 $px-14;
  background-image: unset;

  .k-button-icon {
    color: #9da4b0;
  }

  .k-input-inner {
    padding: 0px;
    z-index: 0;

    &::placeholder {
      color: #b3b8c2;
    }
  }

  &:hover {
    background-color: #fff;
  }

  &:focus-within {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
  }

  &[aria-expanded="true"] {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
    color: #154876;
  }

  &.ng-dirty {
    color: #154876 !important;

    .k-i-caret-alt-down::before {
      color: #154876;
    }
  }

  &.is-invalid {
    border: $px-1 solid #dc362e !important;
    color: #dc362e !important;
    background-color: #fceeee;

    &[aria-expanded="true"] {
      border-color: #dc362e !important;
      box-shadow: unset;
    }

    .k-i-caret-alt-down::before {
      color: #dc362e;
    }
  }

  .k-i-caret-alt-down::before {
    color: #c9cdd3;
  }
}

/****************** checkBox Style **************************/

.checkBox_custom_input {
  width: $px-20;
  height: $px-20;
  accent-color: #2e6fa6;

  &:hover {
    cursor: pointer;
    accent-color: #2e6fa6;
  }
}

.checkBox_cell {
  // padding: $px-12 22px!important;
  // text-align: center !important;
}

/****************** hintPopup Style **************************/

.hintPopub {
  min-width: $px-120;
  height: $px-64;
}

/******************upload Style *********************/

.k-upload {
  min-height: $px-150;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f5f9;
  border: $px-1 solid #e2e8f0;
  flex-direction: column;
  border-radius: $px-6;
}

.k-upload .k-dropzone,
.k-upload .k-upload-dropzone {
  background: #f1f5f9;
  padding: $px-10 $px-14;
  border-radius: $px-6;
  display: flex;
  flex-direction: column-reverse;
  gap: $px-12;

  .k-upload-button-wrap {
    display: flex;
    align-items: center;
    width: unset;

    button {
      background-color: #fff;

      padding: $px-6 $px-12;
      height: $px-36;
      border: $px-1 solid #e2e8f0;
      box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
      border-radius: $px-6;

      .k-button-text {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: $px-14;
        line-height: $px-24;
        color: #64748b;
      }
    }
  }

  .k-dropzone-hint {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: $px-14;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
    color: #64748b;
  }

  .k-upload-status {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: $px-14;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
    color: #64748b;
    gap: $px-6;
  }
}

.k-upload-files {
  background-color: #fff;
  height: $px-72;
  border-radius: $px-6;
  width: 100%;
  padding: $px-6 $px-12;
  display: flex;
  align-items: center;

  .k-file ng-star-inserted k-file-error .k-focus {
    box-shadow: unset !important;
  }

  .k-file.k-focus {
    box-shadow: unset !important;
  }

  .k-file-success .k-progressbar .k-selected {
    background-color: #2fd181 !important;
  }

  .k-file-progress .k-progressbar .k-selected {
    background-color: #74a5db !important;
  }

  .k-file-error .k-progressbar .k-selected {
    background-color: #dc362e !important;
  }

  li {
    padding: 0px !important;
    width: 100%;

    &:focus {
      border: 0px !important;
      border-color: unset !important;
    }

    kendo-progressbar {
      top: 100%;
    }

    .k-file-icon-wrapper {
      padding: $px-7;
      gap: $px-10;
      width: $px-34;
      height: $px-34;
      background-color: #f1f5f9;
      border-radius: $px-9999;
      display: flex;
      justify-content: center;
      align-items: center;

      kendo-icon-wrapper {
        kendo-icon {
          font-size: $px-15 !important;
          color: #2e6fa6;
          text-align: center;
        }
      }
    }

    .k-file-info {
      margin: 0px;
      margin-left: $px-16;

      .k-file-name {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: $px-14;
        line-height: $px-24;
        letter-spacing: -$px-0-2;
        color: #334155;
      }

      .k-file-summary {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: $px-11;
        line-height: $px-20;
        letter-spacing: -$px-0-2;
        color: #94a3b8;
      }
    }

    kendo-upload-file-list-item-action-button {
      .k-upload-actions {
        .k-button {
          kendo-icon-wrapper {
            kendo-icon {
              color: #74a5db;
            }
          }
        }
      }
    }
  }
}

/******************toolTip Style *********************/

.k-popup-transparent {
  border-radius: $px-13;
}

.k-tooltip {
  background-color: #fff;
  width: $px-120;
  height: $px-64;
  border-radius: $px-13;

  .k-tooltip-content {
    display: flex;
    align-items: center;

    img {
      object-fit: none;
      max-height: $px-64;
      width: 100%;
    }
  }

  .k-callout {
    color: #fff;
  }
}

/****************** custom_forms_Users *********************/

.modal-backdrop {
  width: 100%;
  height: 100%;
}

.modal-custom_forms {
  max-width: decrease-by-10-percent(770px) !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: decrease-by-10-percent(13.87px);
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

/****************** custom_forms_Students *********************/

.modal-custom_forms_Student {
  max-width: decrease-by-10-percent(786px) !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

/**************** custom_forms_Districts_School  *******************/

.modal-custom_forms_Districts_School {
  max-width: $px-712 !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

/***************** custom_forms_Districts-Users **********************/

.modal-custom_forms_Districts-Users {
  max-width: $px-712 !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_field_label_button {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            height: $px-24;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

/****************** custom_forms_Incident_Requests *********************/

.modal-custom_forms_Incident_Requests {
  max-width: $px-730 !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

/****************** datepicker_custom_popup *********************/

.datepicker_custom_popup {
  border-radius: $px-13;

  .k-calendar .k-calendar-navigation {
    background-color: #f1f5f9;
  }

  .k-calendar-td {
    span {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: $px-14;
      line-height: $px-24;
    }

    &:hover {
      span {
        background-color: #f1f5f9 !important;
        color: #334155;
      }
    }
  }

  .k-today {
    color: #2e6fa6 !important;
    border: $px-1 solid #2e6fa6;
  }

  .k-selected {
    span {
      background-color: #2e6fa6 !important;

      &:hover {
        color: #2e6fa6 !important;
        border-color: #2e6fa6 !important;
      }
    }
  }

  .k-today.k-selected {
    border: 0px;
  }

  .k-button-flat {
    width: unset !important;
  }

  kendo-calendar-navigation {
    kendo-virtualization {
      .k-reset {
        li {
          span {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: $px-14;
            line-height: $px-24;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -$px-0-2;
            color: #334155;
          }
        }
      }
    }
  }
}

/****************** custom_forms_ardian *********************/

.modal-custom_forms_Guardian {
  max-width: 848px !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

/****************** custom_forms_Regions And Locations *********************/

.modal-custom_forms_RegionsLocations {
  max-width: $px-712 !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }

          .formPlusButtonLabel {
            visibility: hidden;
          }

          .buttonsWraper {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            width: 100%;
            align-items: flex-end;
            padding-left: $px-24;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }

  .k-upload .k-dropzone,
  .k-upload .k-upload-dropzone {
    background-color: #f8fafc;
    height: $px-56;
    padding: $px-10 $px-14;
    border-bottom: $px-1 solid #f1f5f9;

    .k-upload-button-wrap {
      display: flex;
      align-items: center;
      width: unset;

      button {
        background-color: #fff;

        padding: $px-6 $px-12;
        height: $px-36;
        border: $px-1 solid #e2e8f0;
        box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
        border-radius: $px-6;

        .k-button-text {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: $px-14;
          line-height: $px-24;
          color: #64748b;
        }
      }
    }

    .k-dropzone-hint {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: $px-14;
      line-height: $px-24;
      letter-spacing: -$px-0-2;
      color: #64748b;
    }

    .k-upload-status {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: $px-14;
      line-height: $px-24;
      letter-spacing: -$px-0-2;
      color: #64748b;
      gap: $px-6;
    }
  }

  .k-upload-files {
    background-color: #fff;
    height: $px-72;

    width: 100%;
    padding: $px-6 $px-12;
    display: flex;
    align-items: center;

    .k-file ng-star-inserted k-file-error .k-focus {
      box-shadow: unset !important;
    }

    .k-file.k-focus {
      box-shadow: unset !important;
    }

    .k-file-success .k-progressbar .k-selected {
      background-color: #2fd181 !important;
    }

    .k-file-progress .k-progressbar .k-selected {
      background-color: #74a5db !important;
    }

    .k-file-error .k-progressbar .k-selected {
      background-color: #dc362e !important;
    }

    li {
      padding: 0px !important;
      width: 100%;

      &:focus {
        border: 0px !important;
        border-color: unset !important;
      }

      kendo-progressbar {
        top: 100%;
      }

      .k-file-icon-wrapper {
        padding: $px-7;
        gap: $px-10;
        width: $px-34;
        height: $px-34;
        background-color: #f1f5f9;
        border-radius: $px-9999;
        display: flex;
        justify-content: center;
        align-items: center;

        kendo-icon-wrapper {
          kendo-icon {
            font-size: $px-15 !important;
            color: #2e6fa6;
            text-align: center;
          }
        }
      }

      .k-file-info {
        margin: 0px;
        margin-left: $px-16;

        .k-file-name {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: $px-14;
          line-height: $px-24;
          letter-spacing: -$px-0-2;
          color: #334155;
        }

        .k-file-summary {
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: $px-11;
          line-height: $px-20;
          letter-spacing: -$px-0-2;
          color: #94a3b8;
        }
      }

      kendo-upload-file-list-item-action-button {
        .k-upload-actions {
          .k-button {
            kendo-icon-wrapper {
              kendo-icon {
                color: #74a5db;
              }
            }
          }
        }
      }
    }
  }
}

/****************** custom_forms_tools *********************/

.modal-custom_forms_tools {
  max-width: $px-712 !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .hideGridfields {
          display: none;
        }

        .customeRow {
          margin-bottom: $px-19 !important;
        }

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }

          .checkboxRow {
            margin-bottom: $px-19;

            .formsCheckbox {
              position: relative;
              display: flex;
              align-items: center;

              label {
                background-color: #fff;
                border: $px-1 solid #c9cdd3;
                border-radius: $px-6;
                cursor: pointer;
                height: $px-20;
                width: $px-20;
                left: 0;
                position: absolute;
                top: 0;
              }

              label:after {
                border: $px-3 solid #fff;
                border-top: none;
                border-right: none;
                content: "";
                height: $px-6;
                width: $px-13;
                left: decrease-by-10-percent(2.5px);
                top: decrease-by-10-percent(5.5px);
                opacity: 0;
                position: absolute;
                transform: rotate(-45deg);
              }

              input[type="checkbox"] {
                visibility: hidden;
              }

              input[type="checkbox"]:checked + label {
                background-color: #2e6fa6;
                border-color: #2e6fa6;
              }

              input[type="checkbox"]:checked + label:after {
                opacity: 1;
              }
            }

            .checkBox_custom_inputRow {
              width: $px-20;
              height: $px-20;
            }

            display: flex;
            align-items: center;
            gap: $px-12;

            label {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: $px-14;
              line-height: $px-17;
              letter-spacing: decrease-by-10-percent(-0.408px);
              color: #727c8d;

              &:hover {
                cursor: pointer;
              }
            }
          }
          .editButtonsContainer {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;

            .actionButtonsEdit {
              display: flex;
              gap: $px-12;
              align-items: center;
              width: 100%;
            }
          }

          .formPlusButtonLabel {
            visibility: hidden;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

.truncate {
  width: $px-250;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

k-upload.k-invalid,
.k-upload.ng-invalid.ng-touched,
.k-upload.ng-invalid.ng-dirty {
  border-color: #74a5db;
}

.UploadError {
  border-color: #74a5db !important;
}

/****************** custom_forms_addNewSchool_districts *********************/

.modal-custom_forms_addNewSchool_districts {
  max-width: $px-712 !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    .schoolDistrictsForms {
      padding: $px-24;

      .formHeader {
        .headRow {
          .form_head-line {
            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .pageHeader {
            margin-bottom: 0px;
          }
        }

        .row {
          margin-bottom: $px-12;

          .form-radio {
            display: flex;
            gap: $px-16;

            .form-check {
              display: flex;
              gap: $px-8;

              .form-check-input {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                width: $px-20;
                height: $px-20;
                border-radius: 50% !important;
                border: $px-1 solid #2e6fa6 !important;
                outline: none;
                cursor: pointer;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: $px-15;
                  height: $px-15;
                  border-radius: 50%;
                  background-color: transparent;
                  transition:
                    background-color 0.2s ease,
                    transform 0.2s ease;
                }

                &:checked {
                  background-color: #fff !important;

                  &::before {
                    background-color: #2e6fa6;
                    transform: translate(-50%, -50%) scale(0.7);
                  }
                }
              }

              label {
                font-size: $px-14;

                letter-spacing: -$px-0-2;
                color: #334155;
                line-height: $px-40;
                font-weight: 600;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      form {
        .modal-body {
          padding: 0px;

          .row {
            margin-bottom: $px-4;

            .form_field_label {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: $px-14;
              line-height: $px-24;
              letter-spacing: -$px-0-2;
              color: #606977;
              margin-bottom: $px-6;
            }

            .form_error_container {
              height: decrease-by-10-percent(13.87px);
            }

            .form_error_info {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: $px-11;
              letter-spacing: -$px-0-2;
              color: #dc362e;
            }

            .formPlusButtonLabel {
              visibility: hidden;
            }

            .buttonsWraper {
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              width: 100%;
            }
          }

          .headRow {
            margin-bottom: 0px;
          }
        }

        .modal-footer {
          padding: 0px;

          * {
            margin: unset;
          }

          margin-top: $px-20;
          gap: $px-16;
        }
      }
    }
  }
}

/****************** text toolTip Style *********************/

.k-tooltip-closable {
  width: fit-content;
  height: fit-content;
  padding: $px-8;
  background-color: #fff;
  border-radius: $px-13;
  max-width: $px-400;

  .k-tooltip-content {
    .tooltip-container {
      display: flex;
      align-items: flex-start;
      gap: $px-8;

      .tooltip-text {
        font-size: $px-14;
        font-family: "Inter", sans-serif;
        line-height: $px-24;
        letter-spacing: -$px-0-2;
        color: #334155;
        margin: 0;
      }
    }
  }
}

/****************************** notification_Modal  *******************/
.notification-modal {
  padding-top: $px-24;
  background-color: #f8fafc !important;
  width: $px-440;
  position: fixed;
  height: 100%;
  right: 0;
  z-index: 102;
  box-shadow:
    0 $px-8 $px-10 decrease-by-10-percent(-5px) rgba(0, 0, 0, 0.2),
    0 $px-16 $px-24 $px-2 rgba(0, 0, 0, 0.14);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideLeft;
  animation-name: slideLeft;
  top: 0;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    padding-left: $px-24;
    padding-right: $px-24;
    margin-bottom: $px-24;

    .NotifiacationHeadline {
      color: #000;
      font-size: $px-20;
      font-style: normal;
      font-weight: 600;
      line-height: $px-32;
      letter-spacing: -$px-0-3;
    }

    .Actions {
      display: flex;
      justify-content: space-between;
      gap: $px-16;

      .switch {
        display: flex;
        align-items: center;
        gap: $px-13;

        .switchlabel {
          color: #64748b;
          font-size: $px-13;
          font-style: normal;
          font-weight: 400;
          line-height: $px-24;
          letter-spacing: -$px-0-2;
        }

        input[type="checkbox"] {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        label {
          cursor: pointer;
          text-indent: -$px-9999;
          width: $px-30;
          height: $px-16;
          background-color: #fff;
          display: block;
          border-radius: $px-100;
          position: relative;
          border: decrease-by-10-percent(0.65px) solid #c9cdd3;
          margin-bottom: 0px !important;
        }

        label:after {
          content: "";
          position: absolute;
          top: $px-1;
          left: $px-2;
          width: $px-12;
          height: $px-12;
          background-color: #b3b8c2;
          border-radius: $px-90;
          transition: 0.3s;
        }

        input:checked + label {
          background-color: #417eb8;
          border: 0px;
        }

        input:checked + label:after {
          left: calc(100% - $px-2);
          transform: translateX(-100%);
          background-color: #fff;
          top: $px-2;
        }

        label:active:after {
          width: $px-16;
        }
      }

      .ActionButtons {
        display: flex;
        gap: $px-8;
        padding: $px-2 $px-4;
        background-color: #fff;
        border-radius: $px-99;
        align-items: center;

        button {
          width: $px-24;
          height: $px-24;
          background-color: #f6f9fc;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0px;

          svg-icon {
            height: $px-20;
            display: flex;
            align-items: center;
          }

          &:hover {
            background-color: #d9f2ff;

            svg-icon {
              svg {
                path {
                  stroke: #004876;
                }
              }
            }
          }
        }
      }
    }
  }

  .modalBody {
    height: 100%;

    .wrapper {
      height: 100%;

      .k-tabstrip {
        height: 100%;

        .k-tabstrip-items-wrapper {
          display: flex;
          align-items: center;
          border-bottom: 0px;

          .k-button-flat {
            height: $px-20 !important;
            width: $px-20 !important;
            margin-top: $px-10;

            .k-button-icon {
              &::before {
                color: rgb(100, 116, 139) !important;
              }
            }

            border-color: rgba(0, 0, 0, 0.08) !important;
            font-size: $px-14;
            font-weight: 400;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            border-radius: 50%;

            &.k-disable {
              background-color: #fff;
            }

            &.k-tabstrip-prev {
              margin-left: $px-8;
            }

            &.k-tabstrip-next {
              margin-right: $px-8;
            }

            &:hover {
              background-color: #d9f2ff !important;

              .k-button-icon {
                &::before {
                  color: #004876 !important;
                }
              }
            }
          }

          .k-tabstrip-items {
            margin-left: $px-24;

            .k-item {
              color: #64748b;
              padding: $px-8 $px-12;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: $px-10 $px-10 0px 0px;
              font-size: $px-14;
              font-style: normal;
              font-weight: 400;
              line-height: $px-24;
              letter-spacing: -$px-0-2;
              border: 0px;
              cursor: pointer;

              .k-link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: $px-8;
                padding: 0px;

                .countContainer {
                  color: #154876;
                  border-radius: $px-20;
                  min-width: $px-24;
                  height: $px-18;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fad80a;
                  padding: $px-6 $px-6;
                }

                .notificationTag {
                  margin-top: unset;
                  margin-bottom: unset;
                  font-style: normal;
                  font-weight: 500;
                  font-size: $px-12;
                  line-height: $px-15;
                  letter-spacing: -0.45px;
                }
              }

              &:focus {
                box-shadow: unset;
                color: #004876;
              }

              &.k-active {
                color: #004876;
                text-align: center;
                font-weight: 600;
                line-height: $px-24;
              }
            }
          }
        }

        .k-content {
          border-radius: $px-10;
          background-color: #fff;
          padding: $px-12 $px-24;

          .contentHeader {
            color: #64748b;
            font-size: $px-13;
            font-style: normal;
            font-weight: 400;
            line-height: $px-20;
            letter-spacing: -$px-0-2;
            display: flex;
            justify-content: flex-end;
            margin-bottom: $px-12;

            span {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .contentWraper {
            display: flex;
            flex-direction: column;
            gap: $px-16;

            .notiContainerContent {
              display: flex;
              flex-direction: column;
              gap: $px-8;

              .DateContainer {
                position: relative;
                display: flex;
                justify-content: center;

                .line {
                  position: absolute;
                  top: 50%;
                  width: 100%;
                  margin: 0px;
                  z-index: 0;
                  color: #f6f9fc;
                  border: none;
                  height: $px-2;
                  background-color: #f6f9fc;
                  opacity: 1;
                }

                .time {
                  background-color: #f6f9fc;
                  padding: $px-4 $px-8;
                  color: #475569;
                  text-align: center;
                  font-size: $px-11;
                  font-style: normal;
                  font-weight: 500;
                  line-height: $px-16;
                  letter-spacing: -$px-0-2;
                  z-index: 99;
                  border-radius: $px-6;
                }
              }
            }

            .notiSpacer {
              height: $px-94;
            }
          }

          &:focus {
            outline: unset;
          }

          &::-webkit-scrollbar-track {
            background-color: #d9e5f1;
            border-radius: $px-13;
            margin-top: 44px;
          }

          &::-webkit-scrollbar {
            width: $px-10;
            margin-top: $px-20;
            margin-bottom: $px-20;
            border-radius: $px-13;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #417eb8;
            box-shadow: 0px 0px $px-10 $px-2 rgba(0, 0, 0, 0.05);
            border-radius: $px-13;
          }
        }
      }
    }
  }

  .blurContainer {
    position: absolute;
    top: calc(100% - $px-94);
    display: flex;
    justify-content: center;
    align-items: center;
    height: $px-94;
    width: 100%;
    z-index: 9999999999;
    backdrop-filter: blur($px-1);

    .floatButton {
      color: #475569;
      font-size: $px-14;
      font-style: normal;
      font-weight: 500;
      line-height: $px-24;
      letter-spacing: -$px-0-2;
      height: $px-36;
      width: $px-376;

      border-radius: $px-6;
      border: $px-1 solid #cbd5e1;
      background-color: #fff;
      box-shadow: 0px $px-1 $px-2 0px rgba(45, 54, 67, 0.05);
      z-index: 99;
    }
  }

  .messageContainerModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $px-50;
    gap: $px-25;
  }
}

//     }
// }

.noti-holder {
  max-height: 111vh;
  overflow: hidden;
}

.noDataConent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%;

  svg-icon {
    svg {
      path {
        fill: #004876;
      }
    }
  }

  h2 {
    margin-top: $px-16;
    color: #1c1c1c;
    font-size: $px-22;
  }

  p {
    color: #64748b;
    font-size: $px-14;
  }
}

/****************************** notification_Modal_messages  *******************/
.notification_Modal_messages {
  padding-top: $px-24;
  background-color: #f8fafc !important;
  width: 520px;
  position: fixed;
  height: 100%;
  right: 0;
  z-index: 102;
  box-shadow:
    0 $px-8 $px-10 decrease-by-10-percent(-5px) rgba(0, 0, 0, 0.2),
    0 $px-16 $px-24 $px-2 rgba(0, 0, 0, 0.14);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideLeft;
  animation-name: slideLeft;
  top: 0px;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    padding-left: $px-24;
    padding-right: $px-24;
    margin-bottom: $px-24;

    .NotifiacationHeadline {
      color: #000;
      font-size: $px-20;
      font-style: normal;
      font-weight: 600;
      line-height: $px-32;
      letter-spacing: -$px-0-3;
    }

    .Actions {
      display: flex;
      justify-content: space-between;
      gap: $px-16;

      .ActionButtons {
        display: flex;
        gap: $px-8;
        padding: $px-2 $px-4;
        background-color: #fff;
        border-radius: $px-99;
        align-items: center;

        button {
          width: $px-24;
          height: $px-24;
          background-color: #f6f9fc;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0px;

          svg-icon {
            height: $px-20;
            display: flex;
            align-items: center;
          }

          &:hover {
            background-color: #d9f2ff;

            svg-icon {
              svg {
                path {
                  stroke: #004876;
                }
              }
            }
          }
        }
      }
    }
  }

  .modalBody {
    height: 100%;

    .wrapper {
      height: 100%;

      .k-tabstrip {
        height: 100%;

        .k-tabstrip-items-wrapper {
          display: flex;
          align-items: center;
          border-bottom: 0px;

          .k-button-flat {
            height: $px-20 !important;
            width: $px-20 !important;
            margin-top: $px-10;

            .k-button-icon {
              &::before {
                color: rgb(100, 116, 139) !important;
              }
            }

            border-color: rgba(0, 0, 0, 0.08) !important;
            font-size: $px-14;
            font-weight: 400;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            border-radius: 50%;

            &.k-disable {
              background-color: #fff;
            }

            &.k-tabstrip-prev {
              margin-left: $px-8;
            }

            &.k-tabstrip-next {
              margin-right: $px-8;
            }

            &:hover {
              background-color: #d9f2ff !important;

              .k-button-icon {
                &::before {
                  color: #004876 !important;
                }
              }
            }
          }

          .k-tabstrip-items {
            margin-left: $px-8;
            margin-right: $px-8;

            .k-item {
              color: #64748b;
              padding: $px-8 $px-12;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: $px-10 $px-10 0px 0px;
              font-size: $px-14;
              font-style: normal;
              font-weight: 400;
              line-height: $px-24;
              letter-spacing: -$px-0-2;
              border: 0px;
              cursor: pointer;

              .k-link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: $px-8;
                padding: 0px;

                .countContainer {
                  color: #154876;
                  border-radius: $px-20;
                  min-width: $px-24;
                  height: $px-18;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fad80a;
                  padding: $px-6 $px-6;
                }

                .notificationTag {
                  margin-top: unset;
                  margin-bottom: unset;
                  font-style: normal;
                  font-weight: 500;
                  font-size: $px-12;
                  line-height: $px-15;
                  letter-spacing: -0.45px;
                }
              }

              &:focus {
                box-shadow: unset;
                color: #004876;
              }

              &.k-active {
                color: #004876;
                text-align: center;
                font-weight: 600;
                line-height: $px-24;
              }
            }
          }
        }

        .k-content {
          border-radius: $px-10;
          background-color: #fff;
          padding: $px-12 $px-24;

          .contentHeader {
            color: #64748b;
            font-size: $px-13;
            font-style: normal;
            font-weight: 400;
            line-height: $px-20;
            letter-spacing: -$px-0-2;
            display: flex;
            justify-content: flex-end;
            margin-bottom: $px-12;

            span {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .contentWraper {
            display: flex;
            flex-direction: column;
            gap: $px-16;

            .notiContainerContent {
              display: flex;
              flex-direction: column;
              gap: $px-8;

              .DateContainer {
                position: relative;
                display: flex;
                justify-content: center;

                .line {
                  position: absolute;
                  top: 50%;
                  width: 100%;
                  margin: 0px;
                  z-index: 0;
                  color: #f6f9fc;
                  border: none;
                  height: $px-2;
                  background-color: #f6f9fc;
                  opacity: 1;
                }

                .time {
                  background-color: #f6f9fc;
                  padding: $px-4 $px-8;
                  color: #475569;
                  text-align: center;
                  font-size: $px-11;
                  font-style: normal;
                  font-weight: 500;
                  line-height: $px-16;
                  letter-spacing: -$px-0-2;
                  z-index: 99;
                  border-radius: $px-6;
                }
              }
            }

            .notiSpacer {
              height: $px-94;
            }
          }

          &:focus {
            outline: unset;
          }

          &::-webkit-scrollbar-track {
            background-color: #d9e5f1;
            border-radius: $px-13;
            margin-top: $px-15;
          }

          &::-webkit-scrollbar {
            width: $px-10;
            margin-top: $px-20;
            margin-bottom: $px-20;
            border-radius: $px-13;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #417eb8;
            box-shadow: 0px 0px $px-10 $px-2 rgba(0, 0, 0, 0.05);
            border-radius: $px-13;
          }
        }
      }
    }
  }

  .blurContainer {
    position: absolute;
    top: calc(100% - $px-94);
    display: flex;
    justify-content: center;
    align-items: center;
    height: $px-94;
    width: 100%;
    z-index: 9999999999;
    backdrop-filter: blur($px-1);

    .floatButton {
      color: #475569;
      font-size: $px-14;
      font-style: normal;
      font-weight: 500;
      line-height: $px-24;
      letter-spacing: -$px-0-2;
      height: $px-36;
      width: $px-376;

      border-radius: $px-6;
      border: $px-1 solid #cbd5e1;
      background-color: #fff;
      box-shadow: 0px $px-1 $px-2 0px rgba(45, 54, 67, 0.05);
      z-index: 99;
    }
  }

  .messageContainerModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $px-50;
    gap: $px-25;
  }
}

/****************** custom_forms_Guardians *********************/

.modal-custom_forms_Guardians {
  max-width: 848px !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    .guardianForms {
      padding: $px-24;

      .formHeader {
        .headRow {
          .form_head-line {
            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .pageHeader {
            margin-bottom: 0px;
          }
        }

        .row {
          margin-bottom: $px-12;

          .form-radio {
            display: flex;
            gap: $px-16;

            .form-check {
              display: flex;
              gap: $px-8;

              .form-check-input {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                width: $px-20;
                height: $px-20;
                border-radius: 50% !important;
                border: $px-1 solid #2e6fa6 !important;
                outline: none;
                cursor: pointer;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: $px-15;
                  height: $px-15;
                  border-radius: 50%;
                  background-color: transparent;
                  transition:
                    background-color 0.2s ease,
                    transform 0.2s ease;
                }

                &:checked {
                  background-color: #fff !important;

                  &::before {
                    background-color: #2e6fa6;
                    transform: translate(-50%, -50%) scale(0.7);
                  }
                }
              }

              label {
                font-size: $px-14;
                line-height: $px-24;
                letter-spacing: -$px-0-2;
                color: #334155;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      form {
        .modal-body {
          padding: 0px;

          .row {
            margin-bottom: $px-4;

            .form_field_label {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: $px-14;
              line-height: $px-24;
              letter-spacing: -$px-0-2;
              color: #606977;
              margin-bottom: $px-6;
            }

            .form_error_container {
              height: 13.87px;
            }

            .form_error_info {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: $px-11;
              letter-spacing: -$px-0-2;
              color: #dc362e;
            }

            .formPlusButtonLabel {
              visibility: hidden;
            }

            .buttonsWraper {
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              width: 100%;
            }
          }

          .headRow {
            margin-bottom: 0px;
          }
        }

        .modal-footer {
          padding: 0px;

          * {
            margin: unset;
          }

          margin-top: $px-20;
          gap: $px-16;
        }
        .k-upload {
          .k-upload-files {
            padding: $px-10 $px-14;
          }
        }
      }
    }
  }
}

/*************************** button With icon ******************************/

.buttonWithIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $px-13;

  .downloadicon {
    display: flex;
    align-items: center;
    margin-right: $px-8;
    margin-top: -$px-3;
  }
}

/****************** custom_forms_studentsAdd *********************/

.modal-custom_forms_studentsAdd {
  max-width: 848px !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    .studentsForms {
      padding: $px-24;

      .formHeader {
        .headRow {
          .form_head-line {
            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .pageHeader {
            margin-bottom: 0px;
          }
        }

        .row {
          margin-bottom: $px-12;

          .form-radio {
            display: flex;
            gap: $px-16;

            .form-check {
              display: flex;
              gap: $px-8;

              .form-check-input {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                width: $px-20;
                height: $px-20;
                border-radius: 50% !important;
                border: $px-1 solid #2e6fa6 !important;
                outline: none;
                cursor: pointer;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: $px-15;
                  height: $px-15;
                  border-radius: 50%;
                  background-color: transparent;
                  transition:
                    background-color 0.2s ease,
                    transform 0.2s ease;
                }

                &:checked {
                  background-color: #fff !important;

                  &::before {
                    background-color: #2e6fa6;
                    transform: translate(-50%, -50%) scale(0.7);
                  }
                }
              }

              label {
                font-size: $px-14;
                line-height: $px-24;
                letter-spacing: -$px-0-2;
                color: #334155;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      form {
        .modal-body {
          padding: 0px;

          .row {
            margin-bottom: $px-4;

            .form_field_label {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: $px-14;
              line-height: $px-24;
              letter-spacing: -$px-0-2;
              color: #606977;
              margin-bottom: $px-6;
            }

            .form_error_container {
              height: 13.87px;
            }

            .form_error_info {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: $px-11;
              letter-spacing: -$px-0-2;
              color: #dc362e;
            }

            .formPlusButtonLabel {
              visibility: hidden;
            }

            .buttonsWraper {
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              width: 100%;
            }
          }

          .headRow {
            margin-bottom: 0px;
          }
        }

        .modal-footer {
          padding: 0px;

          * {
            margin: unset;
          }

          margin-top: $px-20;
          gap: $px-16;
        }

        .k-upload {
          .k-upload-files {
            padding: $px-10 $px-14;
          }
        }
      }
    }
  }
}

/****************** addLocationDialog *********************/

.modal-addLocationDialog {
  max-width: 744px !important;
  width: 100%;
  margin-top: $px-62;

  .modal-content {
    form {
      padding: $px-24;

      .modal-body {
        padding: 0px;

        .row {
          margin-bottom: $px-4;

          .form_head-line {
            margin-bottom: $px-12;

            .form_custom_Headline {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: $px-16;
              line-height: $px-28;
              letter-spacing: -$px-0-3;
              color: #334155;
            }
          }

          .form_field_label {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-14;
            line-height: $px-24;
            letter-spacing: -$px-0-2;
            color: #606977;
            margin-bottom: $px-6;
          }

          .form_error_container {
            height: 13.87px;
          }

          .form_error_info {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: $px-11;
            letter-spacing: -$px-0-2;
            color: #dc362e;
          }
        }

        .headRow {
          margin-bottom: 0px;
        }
      }

      .modal-footer {
        padding: 0px;

        * {
          margin: unset;
        }

        margin-top: $px-20;
        gap: $px-16;
      }
    }
  }
}

.inputContainerLocation {
  display: flex;
  margin-top: $px-20;
  margin-bottom: $px-20;
  gap: $px-12;

  .form_field_label {
    color: #334155;
    font-size: $px-14;
    font-style: normal;
    font-weight: 500;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
    margin-bottom: 0px;
  }

  .inputWrapper {
    width: 100%;
  }
}

/*********************** MESSAGING PAGE *************************/
.chat-wrapper {
  .sidebar-group {
    .search_filters {
      .filter-item {
        .ng-dirty {
          .k-input-value-text {
            color: #334155;
          }
        }
      }
    }
  }

  .chats {
    &.chats-right {
      .message-content {
        span {
          a {
            color: #fff !important;
          }
        }
      }
    }

    .message-content {
      span {
        a {
          color: #475569;
        }
      }
    }
  }

  .skeleton-image {
    background-color: #f8fafc;
  }
}

/****************** imageViewDialog *********************/

.modal-imageViewDialog {
  max-width: decrease-by-10-percent(850px) !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;

  .modal-content {
    form {
      .headRow {
        margin-bottom: 0px;
        position: relative;

        .pageHeader {
          margin-bottom: 0px;
          position: absolute;
          z-index: 99;
          top: $px-10;
          right: $px-10;

          .btn-close {
            opacity: 1;
            background-color: #eff6ff;
            border-radius: 50%;

            &:focus {
              box-shadow: unset;
              opacity: 1;
            }
          }
        }
      }

      .modal-footer {
        padding: $px-12;

        * {
          margin: unset;
        }

        margin-top: $px-12;
        gap: $px-16;
      }
    }
  }
}

/********************** district dropDown custom style /***************************/

body {
  &:has(.profileContainer) {
    .chat-options {
      .list-inline {
        .chat-option-dropDown {
          .msg-dropdowm-action::before {
            content: "\A";
            border-style: solid;
            border-width: $px-10 $px-15 $px-10 0;
            border-color: transparent rgba(248, 250, 252, 1) transparent
              transparent;
            position: absolute;
            left: $px-9;
            transform: rotate(90deg);
            top: -$px-9;
          }

          .msg-dropdowm-action {
            margin-left: 0px;
          }
        }
      }
    }
  }
  



  

}

/*************************** header apps responsive depending on height  *************************/

@media (min-height: decrease-by-10-percent(766px)) {
  .toolsContainer {
    height: $px-380;
  }
}

/****************************** filters notification_Modal  *******************/
.filtersNotification-modal {
  background-color: #f8fafc !important;
  position: fixed;
  height: 100%;
  right: 0;
  width: $px-1100;
  z-index: 102;
  box-shadow:
    0 $px-8 $px-10 decrease-by-10-percent(-5px) rgba(0, 0, 0, 0.2),
    0 $px-16 $px-24 $px-2 rgba(0, 0, 0, 0.14);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideLeft;
  animation-name: slideLeft;
  top: 0;

  .mainFiltersContainer {
    display: flex;
    height: 100%;

    .notificationContainer {
      width: $px-660;
      padding: $px-24;
      background-color: #fff;

      .notificationHeader {
        margin-bottom: $px-24;

        span {
          color: #000;
          font-size: $px-20;
          font-style: normal;
          font-weight: 600;
          line-height: $px-32;
          letter-spacing: -$px-0-3;
        }
      }

      .notificationBody {
        height: calc(100% - $px-56);

        .hidden {
          display: none;
        }

        .contentWraper {
          height: 100%;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: $px-16;
          padding-right: $px-12;

          .notiContainerContent {
            display: flex;
            flex-direction: column;
            gap: $px-8;

            .DateContainer {
              position: relative;
              display: flex;
              justify-content: center;

              .line {
                position: absolute;
                top: 50%;
                width: 100%;
                margin: 0px;
                z-index: 0;
                color: #f6f9fc;
                border: none;
                height: $px-2;
                background-color: #f6f9fc;
                opacity: 1;
              }

              .time {
                background-color: #f6f9fc;
                padding: $px-4 $px-8;
                color: #475569;
                text-align: center;
                font-size: $px-11;
                font-style: normal;
                font-weight: 500;
                line-height: $px-16;
                letter-spacing: -$px-0-2;
                z-index: 99;
                border-radius: $px-6;
              }
            }
          }

          .notiSpacer {
            height: $px-94;
          }

          &::-webkit-scrollbar-track {
            background-color: #d9e5f1;
            border-radius: $px-13;
            margin-top: $px-20;
          }

          &::-webkit-scrollbar {
            width: $px-10;
            margin-bottom: $px-20;
            border-radius: $px-13;
            margin-top: $px-20;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #417eb8;
            box-shadow: 0px 0px $px-10 $px-2 rgba(0, 0, 0, 0.05);
            border-radius: $px-13;
          }
        }
      }
    }

    .filtersContainer {
      width: $px-440;
      padding: $px-24;
      background-color: #f6f9fc;
      border-left: $px-1 solid #e2e8f0;

      .filtersHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: $px-24;

        span {
          color: #000;
          font-size: $px-20;
          font-style: normal;
          font-weight: 600;
          line-height: $px-32;
          letter-spacing: -$px-0-3;
        }

        .ActionsContainer {
          display: flex;
          gap: $px-8;
          padding: $px-2 $px-4;
          background-color: #fff;
          border-radius: $px-99;
          align-items: center;

          button {
            width: $px-24;
            height: $px-24;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0px;

            svg-icon {
              height: $px-20;
              display: flex;
              align-items: center;
            }

            &:hover {
              background-color: #d9f2ff;

              svg-icon {
                svg {
                  path {
                    stroke: #004876;
                  }
                }
              }
            }
          }
        }
      }

      .filtersBody {
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: $px-24;

        .status {
          span {
            color: #64748b;
            font-size: $px-12;
            font-style: normal;
            font-weight: 500;
            line-height: $px-20;
            letter-spacing: -$px-0-2;
          }

          .switchBox {
            display: flex;
            height: $px-40;
            padding: $px-8 $px-12;
            align-items: center;
            background-color: #fff;
            border-radius: $px-10;
            border: $px-1 solid #f1f5f9;

            .switch {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .switchlabel {
                color: #334155;
                font-size: $px-13;
                font-style: normal;
                font-weight: 500;
                line-height: $px-24;
                letter-spacing: -$px-0-2;
              }

              input[type="checkbox"] {
                height: 0;
                width: 0;
                visibility: hidden;
              }

              label {
                cursor: pointer;
                text-indent: -$px-9999;
                width: $px-30;
                height: $px-16;
                background-color: #fff;
                display: block;
                border-radius: $px-100;
                position: relative;
                border: decrease-by-10-percent(0.65px) solid #c9cdd3;
                margin-bottom: 0px !important;
              }

              label:after {
                content: "";
                position: absolute;
                top: $px-1;
                left: $px-2;
                width: $px-12;
                height: $px-12;
                background-color: #b3b8c2;
                border-radius: $px-90;
                transition: 0.3s;
              }

              input:checked + label {
                background-color: #417eb8;
                border: 0px;
              }

              input:checked + label:after {
                left: calc(100% - $px-2);
                transform: translateX(-100%);
                background-color: #fff;
                top: $px-2;
              }

              label:active:after {
                width: $px-16;
              }
            }
          }
        }

        .category {
          span {
            color: #64748b;
            font-size: $px-12;
            font-style: normal;
            font-weight: 500;
            line-height: $px-20;
            letter-spacing: -$px-0-2;
          }

          .categories {
            display: flex;
            flex-direction: column;
            gap: $px-8;

            .categoryBox {
              height: $px-40;
              display: flex;
              padding: $px-8 $px-12;
              align-items: center;
              justify-content: flex-start;
              gap: $px-8;
              background-color: #fff;
              border-radius: $px-10;
              border: $px-1 solid #f1f5f9;

              .checkBox_custom_input {
                width: $px-16;
                height: $px-16;
                accent-color: #2e6fa6;

                &:hover {
                  cursor: pointer;
                  accent-color: #2e6fa6;
                }
              }

              label {
                margin-bottom: 0px;
                color: #334155;
                font-size: $px-13;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: decrease-by-10-percent(-0.408px);

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }

        .date {
          span {
            color: #64748b;
            font-size: $px-12;
            font-style: normal;
            font-weight: 500;
            line-height: $px-20;
            letter-spacing: -$px-0-2;
          }

          .dateContainer {
            display: flex;
            justify-content: space-between;
            padding-left: $px-4;
            padding-right: $px-4;
          }
        }

        .buttonsContainer {
          display: flex;
          flex-direction: column;
        }

        &::-webkit-scrollbar-track {
          background-color: #d9e5f1;
          border-radius: $px-13;
          margin-top: $px-20;
        }

        &::-webkit-scrollbar {
          width: $px-10;
          margin-bottom: $px-20;
          border-radius: $px-13;
          margin-top: $px-20;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #417eb8;
          box-shadow: 0px 0px $px-10 $px-2 rgba(0, 0, 0, 0.05);
          border-radius: $px-13;
        }
      }
    }
  }
}

/***************** timePicker Custom field style *****************/

.timePicker_custom_field {
  display: flex;
  align-items: center;
  height: $px-40;
  border: 0px !important;
  border-radius: $px-10;
  padding: $px-8 $px-14;
  background-color: #fff;
  color: #b3b8c2;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: $px-14;
  line-height: $px-24;
  letter-spacing: -$px-0-2;

  .k-input-solid {
    box-shadow: unset;
  }

  .k-button-icon {
    color: #9da4b0;
  }

  .k-input-inner {
    padding: 0px;
    z-index: 0;
    font-size: $px-13;

    &::placeholder {
      color: #b3b8c2;
    }
  }

  &:hover {
    background-color: #fff;
  }

  &:focus-within {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-3 #b1dcff;
  }

  &[aria-expanded="true"] {
    border-color: #154876 !important;
    box-shadow:
      0px 0px 0px $px-1 #ffffff,
      0px 0px 0px $px-4 #b1dcff;
    color: #154876;
  }

  &.ng-dirty {
    color: #154876 !important;

    .k-i-caret-alt-down::before {
      color: #154876;
    }
  }

  &.is-invalid {
    border: $px-1 solid #dc362e !important;
    color: #dc362e !important;
    background-color: #fceeee;

    &[aria-expanded="true"] {
      border-color: #dc362e !important;
      box-shadow: unset;
    }

    .k-i-caret-alt-down::before {
      color: #dc362e;
    }

    .k-input-inner {
      &::placeholder {
        color: #dc362e !important;
      }
    }

    .k-button-icon {
      color: #dc362e !important;
    }
  }

  .k-i-caret-alt-down::before {
    color: #c9cdd3;
  }
}

/****************** timePicker_custom_popup *********************/

.timePicker_custom_popup {
  border-radius: $px-13;

  .k-date-tab {
    .k-datetime-selector {
      .k-datetime-calendar-wrap {
        .k-calendar {
          .k-calendar-view {
            .k-calendar-header {
              margin-left: $px-19;
            }

            .k-content {
              .k-calendar-table {
                .k-calendar-tbody {
                  .k-calendar-tr {
                    .k-today {
                      color: #2e6fa6;
                      border: $px-1 solid #2e6fa6;
                    }

                    .k-calendar-td {
                      span {
                        font-family: "Inter", sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: $px-14;
                        line-height: $px-24;
                      }

                      &:hover {
                        span {
                          background-color: #f1f5f9 !important;
                          color: #334155;
                        }
                      }
                    }

                    .k-selected {
                      span {
                        background-color: #2e6fa6;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .k-actions {
      display: flex !important;
      border-radius: $px-13;
    }
  }

  .k-time-tab {
    .k-datetime-selector {
      .k-datetime-time-wrap {
        .k-timeselector {
          .k-time-header {
            .k-button {
              color: #417eb8;
              text-align: center;
              font-size: $px-12;
              font-style: normal;
              font-weight: 500;
              line-height: $px-20;
              letter-spacing: -$px-0-2;
            }
          }

          .k-time-list-container {
            .k-item {
              &:hover {
                span {
                  color: #334155;
                }
              }
            }
          }
        }
      }
    }

    .k-actions {
      display: flex !important;
      border-radius: $px-13;
    }
  }

  .k-datetime-footer {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Inter", sans-serif;
      border-radius: $px-6;
      padding: $px-6 $px-12;
      box-shadow: inset 0px $px-2 0px 0px rgb(250 250 250 / 0.2);
      font-size: $px-12;
      font-style: normal;
      font-weight: 500;
      line-height: $px-20;
      letter-spacing: -$px-0-2;
    }

    .k-time-cancel {
      background-color: #ffffff;
      border: $px-1 solid #64748b;
      color: #64748b;

      &:hover {
        background-color: #f1f5f9;
        box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
      }

      &:active {
        background-color: #f1f5f9;
        box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
        color: #64748b;
      }

      &:disabled {
        background-color: #fff;
        border: $px-1 solid #cbd5e1;
        color: #cbd5e1;
      }
    }

    .k-time-accept {
      background-color: #185b8d;
      border: $px-1 solid #185b8d;
      color: #fff;

      &:hover {
        background-color: #154876;
        box-shadow: 0px $px-4 5px rgba(21, 72, 118, 0.2);
      }

      &:active {
        background-color: #154876;
        box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
        color: #fff;
      }

      &:disabled {
        background-color: #b1dcff;
        border: $px-1 solid #b1dcff;
      }
    }
  }
}

/****************** timePicker_custom_popup *********************/

.timePicker_popup {
  border-radius: $px-13;
  width: $px-318;

  .k-timeselector {
    border-radius: $px-13;

    .k-actions {
      display: flex;
      border-radius: $px-13;
    }

    .k-time-header {
      .k-title {
        color: #000;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: $px-14;
        font-style: normal;
        font-weight: 600;
        line-height: $px-24;
        letter-spacing: -$px-0-2;
      }

      .k-time-now {
        color: #417eb8;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: $px-12;
        font-style: normal;
        font-weight: 500;
        line-height: $px-20;
        letter-spacing: -$px-0-2;
      }
    }

    .k-time-list-container {
      .k-time-highlight {
        border-color: #b1dcff;
        width: 90%;
        left: calc(5%);
        top: calc(50% + $px-11);
        border-width: $px-1;
        border-radius: $px-5;
      }

      .k-time-list-wrapper {
        .k-title {
          color: #64748b;
          text-align: center;
          font-family: "Inter", sans-serif;
          font-size: $px-14;
          font-style: normal;
          font-weight: 600;
          line-height: $px-24;
          letter-spacing: -$px-0-2;
        }

        .k-time-list {
          .k-time-container {
            .k-reset {
              .k-item {
                color: #334155;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: $px-14;
                font-style: normal;
                font-weight: 400;
                line-height: $px-24;
                letter-spacing: -$px-0-2;

                &:hover {
                  color: #2e6fa6;
                }
              }
            }
          }
        }
      }
    }

    .k-time-footer {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter", sans-serif;
        border-radius: $px-6;
        padding: $px-6 $px-12;
        box-shadow: inset 0px $px-2 0px 0px rgb(250 250 250 / 0.2);
        font-size: $px-12;
        font-style: normal;
        font-weight: 500;
        line-height: $px-20;
        letter-spacing: -$px-0-2;
      }

      .k-time-cancel {
        background-color: #ffffff;
        border: $px-1 solid #64748b;
        color: #64748b;

        &:hover {
          background-color: #f1f5f9;
          box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
        }

        &:active {
          background-color: #f1f5f9;
          box-shadow: 0px $px-1 $px-2 rgba(45, 54, 67, 0.05);
          color: #64748b;
        }

        &:disabled {
          background-color: #fff;
          border: $px-1 solid #cbd5e1;
          color: #cbd5e1;
        }
      }

      .k-time-accept {
        background-color: #185b8d;
        border: $px-1 solid #185b8d;
        color: #fff;

        &:hover {
          background: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
        }

        &:active {
          background: #154876;
          box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2);
          color: #fff;
        }

        &:disabled {
          background-color: #b1dcff;
          border: $px-1 solid #b1dcff;
        }
      }
    }
  }
}
/******************* chat option  dropDown **************************/
.chat-options {
  .list-inline {
    gap: $px-8;
    .btn-chat-action {
      background: transparent;
      color: rgba(100, 116, 139, 1);
      border: none;
      padding: 0px;
      border-radius: $px-999;
      width: $px-24;
      height: $px-24;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -$px-6;
      svg {
        width: $px-24 !important;
        height: $px-24;
      }
    }
    .chat-option-dropDown {

      .btn-chat-action:hover {
        background-color: #fff;
      }


      .show {
        border: 0px;
        box-shadow: 0 0 $px-18 $px-9 rgba(45, 54, 67, 0.12);
      }

      .msg-dropdowm-action::before {
        content: "\A";
        border-style: solid;
        border-width: $px-10 $px-15 $px-10 0;
        border-color: transparent rgba(248, 250, 252, 1) transparent transparent;
        position: absolute;
        left: $px-167;
        transform: rotate(90deg);
        top: -$px-12;
      }

      .msg-dropdowm-action {
        margin-left: -$px-9;
      }
    }
  }
}
