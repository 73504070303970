@media (min-width: 892.8px) {
  .login-logo-bg img {
    display: inline-block;
  }

  .login {
    display: inline-block;
    height: 98.1vh;
    margin-top: 6.3px;
    vertical-align: central !important;
    background-image: url("../images/login/desktop-bg.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position-x: right;
    width: calc(90vw + 148.59px);
    overflow: hidden;
  }

  .login-top-bg,
  .login-bottom-bg,
  .login-logo-bg {
    display: none !important;
  }

  .login-logo {
    display: none !important;
  }

  .login-card {
    width: 468px;
    max-width: 100%;
    padding: 39.6px 39.6px;
    gap: 7.2px;
  }
}
