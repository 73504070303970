@import "/src/assets/styles/functions";
@import "/src/assets/styles/variables";
.k-notification-group {
  top: $px-64 !important;
  z-index: 9999;
  position: fixed;
  .k-notification-success {
    background-color: #d7ffeb;
    box-shadow:
      0px $px-20 $px-24 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 decrease-by-10-percent(-4px) rgba(45, 54, 67, 0.04);
    border-radius: $px-10;
    min-height: $px-64;
    min-width: $px-400;
    gap: $px-15;
    padding: $px-20;
    border: 0px;
    kendo-icon-wrapper {
      color: #11a75c;
      .k-icon {
        font-size: $px-18;
      }
    }
    .k-notification-content {
      h5 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: $px-14;
        line-height: $px-24;
        letter-spacing: -$px-0-2;
        color: #11a75c;
        margin: 0px;
      }
    }
    .k-notification-actions {
      color: #11a75c;
    }
  }

  .k-notification-error {
    background-color: #e46962;
    box-shadow:
      0px $px-20 $px-24 -4px rgba(45, 54, 67, 0.04),
      0px $px-8 $px-11 -4px rgba(45, 54, 67, 0.04);
    border-radius: $px-10;
    min-height: $px-64;
    min-width: $px-400;
    gap: $px-15;
    padding: $px-20;
    border: 0px;
    kendo-icon-wrapper {
      color: #fff;
      .k-icon {
        font-size: $px-18;
      }
    }
    .k-notification-content {
      h5 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: $px-14;
        line-height: $px-24;
        letter-spacing: -$px-0-2;
        color: #fff;
        margin: 0px;
      }
    }
    .k-notification-actions {
      color: #fff;
    }
  }
}
