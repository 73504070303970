@media (min-width: 892.8px) {
  .login-logo-bg img {
    display: inline-block;
  }

  .login-area {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    width: 46.5% !important;
    background: none !important;
    box-sizing: border-box;
  }

  .login {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100vh;
    margin: 0; 
    background-image: url("../images/login/desktop-bg.svg");
    background-repeat: no-repeat;
    background-size: contain; 
    background-position: right center; 
    background-attachment: fixed;
    width: 100vw;
  }
  
  .login-top-bg,
  .login-bottom-bg,
  .login-logo-bg {
    display: none !important;
  }

  .login-logo {
    display: none !important;
  }

  .login-card {
    width: 468px;
    max-width: 100%;
    padding: 39.6px 39.6px;
    gap: 7.2px;
  }
}
