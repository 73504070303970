@media (min-width: 518.4px) {
  .login-logo-bg img {
    display: none;
  }

  .login-area {
    position: absolute;
    height: 99.9vh;
    width: 100% !important;
    display: none;
    vertical-align: top !important;

    align-items: center !important;
    padding-left: 0px !important;
  }

  .login-card {
    margin: 0 auto;
    padding: 39.6px 39.6px;
    gap: 7.2px;
    isolation: isolate;
    width: 439.2px;
    max-width: 439.2px;
  }

  .login-logo img {
    width: 235.476px;
  }
  .login-logo {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
}
