/* You can add global styles to this file, and also import other style files */
@import url(~bootstrap-icons/font/bootstrap-icons.css);
@import url(~@fortawesome/fontawesome-free/css/all.css);
@import url(/src/assets/styles/chat.scss);
@import url(assets/fonts/Inter-font/stylesheet.css);
@import url(assets/styles/style.xs.scss);
@import url(assets/styles/style.sm.scss);
@import url(assets/styles/style.md.scss);
@import url(assets/styles/style.lg.scss);
@import url(assets/styles/style.xl.scss);
@import url(assets/styles/style.xxl.scss);
@import "/src/assets/styles/functions";
@import "/src/assets/styles/variables";
html {
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  min-height: 100%;
  display: block;
}

app-root {
  min-height: 99.9vh;
  display: block;
}

app-main-layout,
.content-container {
  min-height: 99.9vh;
}

body::-webkit-scrollbar {
  display: none;
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  padding-top: 32.2px;
}

.content-container:has(.chat-wrapper) {
  background-color: #154876;

  .router-outlet {
    padding-left: 0px !important;
    padding-top: 0px;
    padding-right: $px-13;
    padding-bottom: $px-13 !important;
  }

  .sidebar {
    height: calc(100% - $px-85);
  }
}

.chat-wrapper {
  background-color: #ffffff !important;
  border-radius: $px-20 !important;
}

.router-outlet {
  padding: $px-24;
  width: 100%;
  height: 100% !important;
}

.no-scroll {
  overflow: hidden;
}

.k-animation-container {
  border-radius: $px-4;
}

.k-list-md.k-list .k-list-content ul.k-list-ul li.k-list-item {
  padding: $px-6 $px-12;
  height: $px-36;
}

.k-list-filter .k-searchbox {
  color: #2e6fa6;
  border: $px-1 solid #cbd5e1;
  border-radius: $px-6;
}
.k-list-filter .k-searchbox:hover {
  border: $px-1 solid #2e6fa6;
  color: #94a3b8;
}

.k-i-search::before {
  color: rgba(203, 213, 225, 1);
  font-size: $px-12 !important;
}

.k-list-filter .k-searchbox:active {
  color: #cbd5e1;
  border-color: #cbd5e1;
}

.k-input-inner
  .ng-pristine
  .ng-valid
  .ng-touched:focus
  .k-list-filter
  .k-searchbox {
  color: #000;
  border-color: #000;
}

.k-input-solid:focus-within {
  box-shadow: 0px 0px 0px $px-4 #d9f2ff;
}

.k-list-md.k-list
  .k-list-content
  ul.k-list-ul
  li.k-list-item
  .k-list-item-text {
  font-style: normal;
  font-weight: 400;
  font-size: $px-14;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  color: #334155;
}

.k-list-md.k-list .k-list-content ul.k-list-ul li.k-list-item:hover {
  background-color: #f1f5f9;
}

.k-list-md.k-list .k-list-content ul.k-list-ul li.k-list-item:focus {
  border: 0px;
  background-color: #2e6fa6;
}

.k-list-item.k-selected {
  background-color: #2e6fa6;
}

.k-list-item.k-focus {
  box-shadow: unset;
}

.dropItem {
  color: #334155;
}

.k-list-md.k-list .k-list-content::-webkit-scrollbar {
  display: none;
}

.k-list-md.k-list .k-list-content ul.k-list-ul li.k-list-item.k-selected:hover {
  background-color: #2e6fa6;
}

.login-bg img {
  display: none;
}

.login-logo {
  z-index: 999;

  display: inline-flex;
  vertical-align: middle;
}

.login-area {
  z-index: 999;

  justify-content: center;

  display: inline-flex;
  vertical-align: middle;
  flex-direction: column !important;
}

.login-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #334155 !important;
}

.f-30 {
  font-size: $px-30 !important;
  line-height: $px-30 !important;
}

.f-24 {
  font-size: $px-24 !important;
  line-height: $px-30 !important;
}

.f-18 {
  font-size: $px-18 !important;
  line-height: $px-24 !important;
}

.f-16 {
  font-size: $px-16 !important;
  line-height: $px-24 !important;
}

.bold {
  font-weight: bold;
}

.text-primary {
  color: #334155 !important;
}

.text-secondary {
  color: #94a3b8 !important;
}

.label {
  font-size: $px-14;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  color: #606977;
}

.input-group {
  width: 100%;
}

.login-form-control img {
  position: absolute;
  height: $px-44;
  padding-right: $px-8;
  padding-left: $px-14;
  padding-top: $px-13;
  padding-bottom: $px-16;
}

.login-form-control span.separator {
  position: absolute;
  height: $px-44;
  line-height: $px-41;
  margin-left: $px-34;
  font-size: $px-16;
  color: #dee1e5;
}

.login-form-control .form-control {
  height: $px-44;
  padding-left: $px-51;
}

.form-control:focus,
.form-control:active {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;

  border: $px-1 solid #154876;
  box-shadow: 0px 0px 0px $px-4 #d9f2ff;
}

.form-control:hover {
  border: $px-1 solid #154876;
}

.login-form-control .form-control::placeholder {
  font-size: $px-16;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  color: #b3b8c2;
}

.login-control label {
  display: inline-block;
  line-height: $px-24;
  font-size: $px-14;
  margin-bottom: $px-6;
  color: rgba(96, 105, 119, 1);
}

.btn-block {
  width: 100% !important;
}

.btn-login {
  border-radius: $px-6;
  background-color: #185b8d !important;
  color: #fff;
  font-weight: 500;
  font-size: $px-16;
  line-height: $px-28;

  border-color: rgba(24, 91, 141, 1) !important;
  height: $px-44 !important;
  -webkit-box-shadow: inset 0px $px-4 $px-11 decrease-by-10-percent(-5px) #ffffff !important;
  box-shadow: inset 0px $px-4 $px-11 decrease-by-10-percent(-6px) #ffffff !important;
}

.btn-login:hover {
  background-color: #154876 !important;

  border: $px-1 solid rgba(24, 91, 141, 1);
  color: #fff;

  box-shadow: 0px $px-4 $px-5 rgba(21, 72, 118, 0.2) !important;
}

.btn-login:active {
  background-color: #154876;
  color: #fff !important;
  border: $px-1 solid rgba(24, 91, 141, 1);
  box-shadow: 0px 0px 0px $px-4 #d9f2ff;
  border-radius: $px-6;
}

.btn-login:disabled,
.btn-login[disabled] {
  background-color: #b1dcff !important;
  border-radius: $px-6;
  color: #fff !important;
  border-color: #b1dcff !important;
}

.mb-12 {
  margin-bottom: $px-12;
}

.mb-40 {
  margin-bottom: $px-40;
}

.mb-4 {
  margin-bottom: $px-4 !important;
}

.mb-20 {
  margin-bottom: $px-20 !important;
}

.mb-58 {
  margin-bottom: $px-58 !important;
}

.login-control {
  margin-bottom: 14.4px !important;
}

.Remember-check {
  margin-bottom: 39.12px;
}

.form-check-input {
  background-color: #f9fafb;
  border: $px-1 solid #afbaca !important;
  border-radius: $px-4 !important;
  width: $px-20;
  height: $px-20;
}

.form-check-input:checked {
  background-color: #1b4dff;
  border-color: #1b4dff;
  box-shadow: none !important;
}

.form-check-input:hover {
  border-color: #b4c4ff !important;
  box-shadow: 0px 0px 0px $px-2 #ecf2fe;
}

.Remember-check label {
  font-size: $px-16;
  line-height: $px-28;
  letter-spacing: -0.3px;
  color: #64748b;
}

.sidebar-expanded {
  .side-bar-tag {
    .sideMenu-overlay {
      width: calc(112.5vw - $px-219);
    }
  }
}

.sideMenu-overlay {
  background-color: #64748b26;
  height: 153vh;
  position: absolute;
  top: 0px;
  right: 0;
}

.login-list {
  list-style: none;
  text-align: center;
  padding: 0px;
  margin-top: 39.6px;
  margin-bottom: 21.6px;
}

.login-list li {
  display: inline-block;
  font-weight: 500;
  font-size: 14.4px;
  line-height: 25.2px;
  letter-spacing: -0.27px;
  color: #5e92cb;
  margin: 0 9px;
  cursor: pointer;
}

.login-list li img {
  padding-left: 3.6px;
}

.login-list li.separator {
  color: #cbd5e1;
}

.nav-item.dropdown .dropdown-toggle::after {
  content: "\f107";
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  border-top: 0px;
  vertical-align: 0;
  max-width: 14.33px;
}

.nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f106" !important;
}

.k-i-caret-alt-down::before {
  content: "\f107";
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  border-top: 0px;
  vertical-align: 0;
  color: rgba(226, 232, 240, 1);
}

.k-i-caret-alt-right::before {
  content: "\f105";
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  border-top: 0px;
  vertical-align: 0;
}

.k-i-caret-alt-left::before {
  content: "\f104";
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  border-top: 0px;
  vertical-align: 0;
}

@keyframes slideIn {
  0% {
    transform: translateY(0.9rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(0.9rem);
    opacity: 0;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0.9rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0rem);
    opacity: 1;
  }

  0% {
    transform: translateX(0.9rem);
    opacity: 0;
  }
}

.mainSidebar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  max-height: 100%;
}

.mainSidebar::-webkit-scrollbar {
  display: none;
}

.line {
  margin-top: $px-8;
  margin-bottom: $px-16;
}

.sidebar-text {
  font-weight: 700;
  font-size: $px-11;
  line-height: $px-28;
  letter-spacing: -$px-0-2;
  color: #e2e8f0;
  margin-bottom: $px-8;
  margin-left: $px-20 !important;
  width: 100% !important;
  display: block;
  text-align: left !important;
}

.sidebar-text-tools {
  margin-left: $px-13 !important;
  width: auto !important;
  margin-right: $px-13;
}

.small-sidebar-text {
  margin-left: $px-10 !important;
  text-align: center;
}

.tools {
  position: relative;
  margin-bottom: $px-8 !important;
  margin-right: $px-10 !important;
  margin-left: $px-10 !important;
}

.child-submenu {
  margin-top: 0px !important;
}

.child-submenu li {
  width: $px-220 !important;
  display: block !important;
  margin-bottom: $px-2 !important;
}

.sidebar {
  height: 95%;
  z-index: 1;
  background-color: #154876 !important;
  width: $px-64;
  min-width: $px-64;
  .side-bar-tag {
    .sidebar {
      ul.cui-menu-left-list {
        li.menu-item.hasMenu {
          a {
            &::after {
              font-family: var(--fa-style-family, "Font Awesome 6 Free");
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: var(--fa-display, inline-block);
              font-style: normal;
              font-variant: normal;

              text-rendering: auto;
              font-weight: var(--fa-style, 900);
              content: "\f054";
              font-size: $px-10;
              line-height: $px-44;
              float: right;
              color: #fff;
            }
          }
        }

        li.menu-item.hasMenu.active {
          a {
            &::after {
              color: #154876;
            }
          }
        }

        li.menu-item {
          font-weight: 500;
          font-size: $px-14;
          line-height: $px-24;

          .main-menu-link {
            .flux-micon {
              svg-icon {
                svg {
                  g {
                    g {
                      path {
                        fill: #ffffff;
                      }
                    }
                  }
                }
              }
            }
          }

          &.active {
            &:hover {
              background-color: #ffd600 !important;
            }

            background-color: #ffd600;

            &::after {
              color: #154876;
            }

            .main-menu-link {
              .flux-mtext {
                color: #154876;
              }

              .flux-micon {
                svg-icon {
                  svg {
                    g {
                      g {
                        path {
                          fill: #154876;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &:hover {
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0.1)
              ),
              #154876;

            ul {
              min-height: $px-36;

              li {
                z-index: 99;
                position: relative;
                width: 100%;
                text-align: left;
                min-height: $px-44;
                padding: 0px !important;
                height: auto;

                &:hover {
                  background-color: #f1f5f9 !important;

                  a {
                    color: #154876 !important;

                    .subMenuNotSelected::after {
                      color: #154876 !important;
                    }
                  }
                }

                a {
                  padding: $px-10 $px-16 !important;
                  font-weight: 400;
                  font-size: $px-14;
                  line-height: $px-24;
                  text-decoration: none;
                  color: rgba(51, 65, 85, 1) !important;
                  display: block;
                  cursor: pointer;

                  .subMenuNotSelected::after {
                    color: rgba(51, 65, 85, 1) !important;
                  }

                  .subMenuSelected::after {
                    color: rgba(51, 65, 85, 1) !important;
                  }
                }

                ul {
                  position: relative;
                  margin-top: 0px;

                  border-radius: 0px !important;
                  box-shadow: none;

                  left: 0px !important;
                  padding: $px-2 $px-20;

                  li {
                    border-radius: $px-6;
                  }
                }
              }

              padding: 0px;
              width: $px-245;
              box-shadow: 0 $px-10 $px-15 #22292f1a;

              display: block;
              position: absolute;
              z-index: 1;
              border: none;
              margin-top: -$px-44;
              border-radius: 0.3222rem;
              -webkit-animation-duration: 0.3s;
              animation-duration: 0.3s;
              -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
              -webkit-animation-name: slideIn;
              animation-name: slideIn;
              background-color: #fff;
              list-style: none !important;
            }
          }

          ul {
            li {
              ul {
                li {
                  &.active {
                    &:hover {
                      background-color: #f1f5f9 !important;
                    }

                    background-color: #f1f5f9 !important;
                    width: $px-220 !important;
                    display: block !important;

                    .child-subMenu-link {
                      .dot-micon::before {
                        background-color: #334155;
                      }
                      color: #154876 !important;
                      font-weight: 500;
                      width: $px-300 !important;
                      display: block !important;
                    }
                  }
                  .child-subMenu-link {
                    width: $px-300 !important;
                    display: block !important;
                  }
                }
              }

              &.active {
                &:hover {
                  background-color: #f1f5f9 !important;
                }

                background-color: #f1f5f9 !important;

                .subMenu-link {
                  .flux-mtext {
                    color: #154876 !important;
                    font-weight: 500;
                  }

                  .subMenuNotSelected::after {
                    color: #154876 !important;
                  }

                  .subMenuSelected::after {
                    color: #154876 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.subMenu-link::after {
  display: none !important;
}

.sidebar.sidebar-closed {
  .side-bar-tag {
    .sideMenu-overlay {
      width: calc(112.5vw - $px-64);
    }
    .sidebar {
      ul.cui-menu-left-list {
        list-style: none;
        padding-left: 0px;
        text-align: center;

        li.menu-item {
          a {
            &::after {
              display: none;
            }
          }

          width: $px-64;
          height: $px-44;

          &:hover {
            ul {
              left: $px-64;
              min-height: $px-44 !important;

              &::after {
                content: "";
                width: $px-252;
                position: absolute;
                height: $px-60;
                left: 0;
              }
            }
          }

          .main-menu-link {
            padding: 0px $px-16;
            line-height: $px-44;
            width: 100%;
            height: 100%;
            display: block;
            text-decoration: none;

            .flux-mtext {
              display: none;
            }

            &::after {
              display: none;
            }
          }
        }
      }
      .mainSidebar {
        .cui-menu-left-list {
          .toolsContainer {
            .tools {
              width: $px-44 !important;
            }
          }
        }
      }
    }
  }
}

.sidebar.sidebar-expanded {
  .side-bar-tag {
    ul.cui-menu-left-list {
      list-style: none;
      padding-left: 0px;
      text-align: left;

      li.menu-item {
        &:hover {
          ul {
            left: $px-220;
            min-height: $px-44 !important;

            &::after {
              content: "";
              width: $px-252;
              position: absolute;
              height: $px-60;
              left: 0;
            }
          }
        }

        .main-menu-link {
          padding: 0px $px-20;
          display: inline-block;
          width: 100%;
          text-decoration: none;
          line-height: $px-44;

          .flux-mtext {
            padding-left: $px-16;
            text-transform: capitalize;
            color: #fff;
          }
        }
      }
    }
  }
}

.menu-item.hasMenu ul {
  display: none;
}

.side-menu-hr hr {
  border-color: rgba(203, 213, 225, 1);
  margin-top: $px-10;
  margin-bottom: $px-16;
}

ul.child-submenu {
  li {
    a {
      .dot-micon {
        &::before {
          content: " ";
          background-color: #334155;
          width: $px-6 !important;
          height: $px-6 !important;
          display: inline-block;
          border-radius: 100%;
          bottom: $px-2;
          position: relative;
          margin-right: $px-8;
        }
      }
    }

    &:hover {
      a {
        .dot-micon {
          &::before {
            content: " ";
          }
        }
      }
    }
  }
}

.subMenuNotSelected::after {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-weight: var(--fa-style, 900);
  content: "\f054";
  position: absolute;
  font-size: $px-10;
  margin-top: $px-8;
  right: $px-16;
  color: #fff;
}

.subMenuSelected::after {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-weight: var(--fa-style, 900);
  content: "\f078";
  position: absolute;
  font-size: $px-10;
  margin-top: $px-8;
  right: $px-16;
  color: #fff;
}

a {
  cursor: pointer !important;
}
.btn-user-avatar {
  background-color: #f8fafc;
  border: decrease-by-10-percent(2.25px) solid #154876;
  border-radius: 50%;
  width: $px-36;
  height: $px-36;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    display: none;
  }

  &:hover {
    border: decrease-by-10-percent(2.25px) solid #ffd600 !important;
  }
}

.rightContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  padding: 0;
}
app-svg-renderer {
  display: flex;
  align-items: center;
}

kendo-dropdownlist {
  width: $px-160;
  height: $px-40;
  background-color: #1c4d7a;
  border: none;
  padding: $px-4 $px-6 $px-4 $px-8;
}

kendo-dropdownlist:hover {
  background-color: #446d91;
  box-shadow: 0px $px-2 0px rgba(255, 255, 255, 0.3);
}

kendo-dropdownlist:hover .value {
  color: #fff;
}

kendo-dropdownlist:hover .title {
  color: #fff;
}

kendo-dropdownlist[aria-expanded="true"] {
  background-color: #ffffff;
  box-shadow: 0px $px-2 0px rgba(255, 255, 255, 0.3);
}

kendo-dropdownlist[aria-expanded="true"] .value {
  color: #154876;
}

kendo-dropdownlist[aria-expanded="true"] .title {
  color: #94a3b8;
}

:host ::ng-deep app-svg-renderer {
  height: $px-24;
}

.iconContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.title {
  font-weight: 700;
  font-size: $px-10;
  letter-spacing: -$px-0-2;
  color: #94a3b8;
}

.value {
  font-weight: 500;
  font-size: $px-14;
  letter-spacing: -$px-0-2;
  color: rgba(226, 232, 240, 1);
}

:host ::ng-deep .k-input-value-text {
  height: $px-40 !important;
  display: flex;
  align-items: center;
}

:host ::ng-deep .k-icon-button .k-icon {
  color: #e2e8f0;
}

:host ::ng-deep .k-input-inner {
  padding: 0px;
}

/******* count style *******/

.fa-stack-notification {
  color: #154876;
  position: absolute;
  left: auto;
  top: 11.5px;
  border-radius: $px-20;
  width: $px-24;
  height: $px-18;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fad80a;
  margin-left: $px-13;
}

.notificationTag {
  margin-top: unset;
  margin-bottom: unset;
  font-style: normal;
  font-weight: 500;
  font-size: $px-12;
  line-height: $px-15;
  letter-spacing: decrease-by-10-percent(-0.45px);
}

.btn-user-action:hover {
  background-color: #f1f5f9 !important;
  color: #154876 !important;
}

.btn-user-action:hover svg-icon svg g g path {
  fill: #154876 !important;
}

.btn-user-action.active {
  background-color: #2e6fa6;
  color: #ffffff;
}

.btn-user-action.active svg-icon svg g g path {
  fill: #fff !important;
  color: rgb(51, 65, 85);
}

.btn-user-action svg-icon {
  position: relative;
  top: -$px-1;
  margin-right: $px-6;
}

.btn-user-action {
  width: $px-153;
  font-size: $px-14;
  line-height: $px-24;
  letter-spacing: -$px-0-2;
  color: #475569;

  height: $px-44;
}

.btn-user-action svg-icon svg g g path {
  fill: rgba(114, 124, 141, 1) !important;
}

.user-avtion-ngbDropdownMenu {
  padding: 0px !important;
  min-width: auto !important;
  box-shadow: 0 $px-10 $px-15 rgba(34, 41, 47, 0.1019607843);
  border-radius: $px-6;
  border: none !important;
}

.user-avtion-ngbDropdownMenu .btn-user-action:first-child {
  border-top-left-radius: $px-6 !important;
  border-top-right-radius: $px-6 !important;
}

.user-avtion-ngbDropdownMenu .btn-user-action:last-child {
  border-bottom-left-radius: $px-6 !important;
  border-bottom-right-radius: $px-6 !important;
}

.show.dropdown .btn-user-avatar {
  border: decrease-by-10-percent(2.25px) solid #ffd600;
}

.k-input-solid:focus-within:focus {
  box-shadow: none !important;
}

.header-popup-ddl {
  box-shadow: 0 $px-10 $px-15 rgb(34 41 47 / 20%);
  border-radius: $px-6;

  .k-list-md {
    border-bottom-left-radius: $px-6 !important;
    border-bottom-right-radius: $px-6 !important;
  }
}

a::selection {
  background: transparent !important;
}

.flux-mtext::selection {
  background: transparent !important;
}

.toolsContainer {
  height: $px-275;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: $px-4 0px;
  background-color: #154876;
}

.toolsContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $px-6 #154876;
  border-radius: $px-10;
  background-color: #154876;
}

.toolsContainer::-webkit-scrollbar {
  width: $px-6;
  background-color: #154876;
}

.toolsContainer::-webkit-scrollbar-thumb {
  border-radius: $px-10;
  -webkit-box-shadow: inset 0 0 $px-6 #154876;
  background-color: #555;
}

.notification-modal {
  position: fixed;
  height: 100%;
  width: $px-412;
  right: 0;
  background-color: #fff;
  z-index: 102;
  box-shadow:
    0 $px-8 $px-10 decrease-by-10-percent(-5px) rgba(0, 0, 0, 0.2),
    0 $px-16 $px-24 $px-2 rgba(0, 0, 0, 0.14);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideLeft;
  animation-name: slideLeft;

  .notification-header {
    background-color: #f1f5f9 !important;
    height: $px-62;
    font-weight: 500;
    font-size: $px-18;
    color: #154876 !important;
    line-height: $px-62;
    padding: 0 $px-16;
  }
}

.messages-modal {
  position: fixed;
  height: 100%;
  width: $px-412;
  right: 0;
  background-color: #fff;
  z-index: 102;
  box-shadow:
    0 $px-8 $px-10 decrease-by-10-percent(-5px) rgba(0, 0, 0, 0.2),
    0 $px-16 $px-24 $px-2 rgba(0, 0, 0, 0.14);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideLeft;
  animation-name: slideLeft;

  .messages-header {
    background-color: #f1f5f9 !important;
    height: $px-62;
    font-weight: 500;
    font-size: $px-18;
    color: #154876 !important;
    line-height: $px-62;
    padding: 0 $px-16;
  }
}

.notification-close-icon {
  cursor: pointer;
}

.notification-close-icon:hover {
  svg-icon svg path {
    stroke: #154876;
  }
}

.messages-close-icon {
  cursor: pointer;
}

.messages-close-icon:hover {
  svg-icon svg path {
    stroke: #154876;
  }
}

.tools-full-view {
  width: $px-200;
}

.k-pager-sizes {
  z-index: 0;
  font-size: 12.6px;
}

.router-lg-view {
  margin-left: $px-156;
  width: calc(100% - $px-158) !important;
  height: 100%;
}

.router-sm-view {
  height: 100%;
}

.gridContainer {
  height: calc(90vh - $px-120) !important;

  kendo-grid {
    max-height: 100% !important;
  }

  .edit-buttons .k-button-solid-base:focus,
  .edit-buttons .k-button-solid-base.k-focus {
    box-shadow: none;
  }
}

kendo-label > .k-label {
  color: #b3b8c2 !important;
  opacity: 1 !important;
}

.k-grid {
  color: #2e6fa6 !important;
  opacity: 1 !important;
}

.k-pager {
  color: #b3b8c2 !important;
  opacity: 1 !important;
}

.k-pager-info {
  opacity: 1 !important;
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  color: #ffffff !important;
  background-color: #2e6fa6;
}

.k-list-md.k-list
  .k-list-content
  ul.k-list-ul
  li.k-list-item.k-selected
  .k-list-item-text {
  color: #ffffff !important;
}

.k-pager-numbers .k-button.k-selected::before {
  display: none !important;
}

.k-pager-numbers .k-selected {
  background-color: #2e6fa6 !important;
  z-index: 0;
}

.k-checkbox {
  border-color: #c9cdd3;
  background-color: #ffffff;
}

.k-grid-header .k-hierarchy-cell .k-svg-icon.k-active {
  color: white;
  background-color: #417eb8;
}

.k-grid-header .k-grid-header-menu.k-grid-column-menu.k-active:hover,
.k-grid-header .k-hierarchy-cell .k-icon:hover,
.k-grid-header .k-hierarchy-cell .k-svg-icon.k-active:hover {
  background-color: #417eb8 !important;
  color: #fff !important;
}

.btn-clear-column-filter {
  padding: $px-4;
  width: calc(1.28571428574em + $px-10);
  height: calc(1.28571428574em + $px-10);
  margin-left: auto;
  cursor: pointer;
}

.k-grid-pager {
  height: $px-40 !important;
  border-bottom-right-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}

.k-button-flat {
  width: auto !important;
  height: auto !important;
}

.k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist {
  height: $px-32 !important;

  .k-input-inner {
    color: #2e6fa6 !important;
  }
}

.child-subMenu-link::after {
  display: none !important;
}

#pdfGrid {
  .k-grid-header {
    padding-left: $px-50 !important;
  }

  .k-column-title {
    white-space: pre-wrap;
  }
}

.closeConversation-modal {
  .modal-dialog {
    width: $px-400;

    .modal-content {
      .modal-header {
        background-color: #fff !important;
        padding: $px-24;
        padding-bottom: 0px !important;
      }

      .modal-body {
        padding-left: $px-24;
        padding-right: $px-24;
        padding-bottom: $px-32;

        .danger-modal-icon {
          width: $px-40 !important;
          height: $px-40 !important;
          border-radius: $px-6;
          background-color: #fceeee99;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          bottom: $px-20;
        }
      }
    }
  }
}

.delete-Conversation-modal {
  .modal-dialog {
    width: $px-400;

    .modal-content {
      .modal-header {
        background-color: #fff !important;
        padding: $px-24;
        padding-bottom: 0px !important;
      }

      .modal-body {
        padding-left: $px-24;
        padding-right: $px-24;
        padding-bottom: $px-32;

        .danger-modal-icon {
          width: $px-40 !important;
          height: $px-40 !important;
          border-radius: $px-6;
          background-color: #fceeee99;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          bottom: $px-20;
        }
      }
    }
  }
}

.k-selected.k-list-optionlabel {
  color: #ffffff !important;
  background-color: #2e6fa6;
  padding: $px-6 $px-12 !important;
  height: $px-36;
}

.k-selected.k-list-optionlabel:hover {
  background-color: #2e6fa6 !important;
}

.myCursor .k-cell-inner .k-link {
  cursor: default;
}

.left .k-cell-inner .k-link {
  padding-left: 0px !important;
  cursor: default;
}

.overlay-container,
.overlay-container * {
  pointer-events: unset !important;
}

.overlay-container .toast-container {
  margin-top: $px-55;
  max-height: 100%;
  overflow: auto;
  padding-bottom: $px-134;
}

@-moz-document url-prefix() {
  .content-container {
    .router-outlet .chat-wrapper {
      margin: decrease-by-10-percent(-24px) decrease-by-10-percent(-24px) decrease-by-10-percent(-24px) 0 !important;
    }
  }
}

.k-input-inner,
.k-input-inner:focus,
.k-input-inner:active,
.k-dateinput,
.k-dateinput:focus .k-dateinput:active {
  border-width: 0px !important;
  outline-width: 0px !important;
  box-shadow: none !important;
}
app-line-chart,
app-bar-stack-normalization-chart {
  max-width: 100%;
  min-width: 100%;
}
.chart-content {
  max-width: 100%;
  width: 100%;
  height: decrease-by-10-percent(450px);
}
kendo-grid-toolbar {
  display: flex;
}
.input-wrapper {
  flex-basis: 100%;
  text-align: center;
  min-width: $px-250;
  max-width: $px-300;
}
.date-badge {
  padding-left: $px-8;
  padding-right: $px-8;
  padding-top: $px-4;
  padding-bottom: $px-4;
  background: #f8fafc;
  border-radius: $px-6;
  border: $px-0-5 #e2e8f0 solid;
}
.map-title {
  color: #0f172a;
  font-size: $px-18;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: $px-30;
  word-wrap: break-word;
}
.map-card {
  background: var(--Gray-Variant-50, #f8fafc);
}
.ride-card {
  border-radius: $px-10;
  margin-bottom: $px-24;
  border: $px-0-5 solid var(--Gray-Variant-200, #e2e8f0);
  background: var(--Gray-Variant-50, #f8fafc);
  .ride-card-body {
    flex: 1 1 auto;
    padding: $px-16;
    color: var(--bs-card-color);
  }
}
.information {
  .title {
    color: #64748b;
    font-size: $px-11;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -$px-0-2;
  }
  .value {
    color: #334155;
    font-size: $px-14;
    font-style: normal;
    font-weight: 500;
    line-height: $px-24;
    letter-spacing: -$px-0-2;
  }
}
.info-card {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: $px-12;
}
.ride-card-body {
  .separator {
    border-radius: $px-10;
    border: $px-0-5 solid var(--Gray-Variant-200, #e2e8f0);
    background: var(--Gray-Variant-50, #f8fafc);
    margin: $px-16 0;
  }
}
.info-section {
  .info-card {
    &:first-child {
      border-left: 0px !important;
    }
    border-left: $px-0-5 solid var(--Gray-Variant-200, #e2e8f0);
  }
}

.table-view-grid {
  width: 100%;
  display: table;
}
.table-view-row {
  display: table-row;
}
.table-view-cell {
  &:first-child {
    border-left: 0px !important;
    color: #64748b;
    font-size: $px-12;
  }
  &:last-child {
    color: #334155;
    font-size: $px-14;
    font-weight: 500;
  }
  border-left: $px-0-5 solid #e2e8f0;
  display: table-cell;
  border-bottom: $px-0-5 solid #e2e8f0;
  padding: $px-10 $px-8;
}
@media (min-width: decrease-by-10-percent(768px)) {
  .k-grid-toolbar {
    flex-direction: column !important;
    justify-content: start;
    align-items: flex-start;
  }
}

@media (min-width: decrease-by-10-percent(992px)) {
  .k-grid-toolbar {
    flex-direction: row !important;
  }
}

.filter-buttonsContainer {
  margin-left: auto;
  gap: $px-10;
}
.k-toolbar-md::before {
  height: 0 !important;
}
