@media only screen and (min-device-width: 921.6px) and (max-device-height: 1229.4px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .login-bg {
    height: 99.9vh;
    z-index: 999;
    justify-content: center;
    display: inline-flex;
    vertical-align: middle;
    flex-direction: column;
    text-align: center;
  }

  .login-bg img {
    display: block;
    max-width: 100%;
    width: calc(100% - 198px);
    margin: auto;
  }

  .login-card {
    max-width: 100%;
  }

  .login {
    display: inline-block;
    height: 99.9vh;
    background-image: url("../images/login/top-bg.svg"),
      url("../images/login/bottom-bg.svg");
    background-repeat: no-repeat;
    background-size:
      53.472% auto,
      44.792% auto;
    background-position-y: top, bottom;
    width: calc(90vw + 10%);
    background-position-x: right;
  }
}
