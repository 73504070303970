@media (min-width: 691.2px) {
  .login-logo-bg img {
    display: none;
  }

  .login-card {
    padding: 39.6px 39.6px;
    gap: 7.2px;
    isolation: isolate;
    width: 360px;
    max-width: 439.2px;
  }

  .login-logo img {
    width: 235.476px;
  }

  .login-logo {
    justify-content: center;
    align-items: center;
  }
}
